/* eslint-disable no-prototype-builtins */
import {
  Component,
  ViewEncapsulation,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  TemplateRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import * as analyticsactions from './store/analytics.actions';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import {
  BarChartData,
  LineChartData,
  PieChartData,
  allCharts,
  skeletonData,
} from 'src/app/appdata/analytics.data';
import { selectCCAnalyticsData } from './store/analytics.selectors';
import { AnalyticsData } from 'src/app/appdata/analytics.model';
import { SharedService } from 'src/app/services/shared.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnalyticsComponent implements OnInit, AfterViewInit, OnDestroy {
  allCharts = allCharts;
  chartData = new BehaviorSubject<ChartData>(null);
  chartOptions = new BehaviorSubject<ChartConfiguration['options']>({});
  chartType = new BehaviorSubject<ChartType>(null);
  selectedChart = 'Bar Graph';
  analytics_data: AnalyticsData;
  skeletonData = skeletonData;
  mainTemplate = new BehaviorSubject<TemplateRef<any>>(null);
  active = 1;
  destroy$: Subject<boolean> = new Subject<boolean>();
  channelSub: Subscription;
  analyticsDataSubscription: Subscription;
  account_dtls: any = {
    accountid: null,
    bspid: null,
    wabano: null,
    channel: null,
    startdate: null,
    enddate: null,
  };
  barchatdata = new BarChartData();
  linechatdata = new LineChartData();
  datasets: any = [
    {
      data: [],
      label: 'Submitted',
      borderColor: '#00A8BA',
      backgroundColor: '#00A8BA',
      order: 0,
    },
    {
      data: [],
      label: 'Sent',
      borderColor: '#65758A',
      backgroundColor: '#65758A',
      order: 1,
    },
    {
      data: [],
      label: 'Delivered',
      borderColor: '#9EFF97',
      backgroundColor: '#9EFF97',
      order: 2,
    },
    {
      data: [],
      label: 'Read',
      borderColor: '#1184D3',
      backgroundColor: '#1184D3',
      order: 3,
    },
    {
      data: [],
      label: 'Unread',
      borderColor: '#00D1BD',
      backgroundColor: '#00D1BD',
      order: 4,
    },
    {
      data: [],
      label: 'Pending',
      borderColor: '#D463F0',
      backgroundColor: '#D463F0',
      order: 5,
    },
    {
      data: [],
      label: 'Failed',
      borderColor: '#FA5948',
      backgroundColor: '#FA5948',
      order: 6,
    }
  ];
  selectedDataStat: any = null;

  @ViewChild('chartTemp') private chartTemplate!: TemplateRef<any>;
  @ViewChild('skeletonTemp') private skeletonTemplate!: TemplateRef<any>;
  @ViewChild('noanalyticsdata') private noanalyticsdata!: TemplateRef<any>;

  constructor(
    private alertMsg: AlertService,
    private calendar: NgbCalendar,
    private shareservice: SharedService,
    public loader: LoaderService,
    private readonly store: Store,
    private pieChartData: PieChartData,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.channelSub = this.shareservice.selectedBA$.subscribe((value: any) => {
      if (value) {
        this.account_dtls.accountid = value.channel_credentials.account_id;
        this.account_dtls.bspid = value.channel_credentials.bsp_id;
        this.account_dtls.wabano = value.channel_credentials.waba_number;
        this.account_dtls.channel =
          value.channel_credentials.channel_name.toLowerCase();

        const fromDate = this.calendar.getPrev(
          this.calendar.getToday(),
          'd',
          7
        );
        const toDate = this.calendar.getToday();
        this.account_dtls.startdate = `${fromDate.year}-${fromDate.month}-${fromDate.day}`;
        this.account_dtls.enddate = `${toDate.year}-${toDate.month}-${toDate.day}`;
        this.store.dispatch(
          analyticsactions.fetchccanalytics({
            accountid: this.account_dtls.accountid,
            wabano: this.account_dtls.wabano,
            startdate: this.account_dtls.startdate,
            enddate: this.account_dtls.enddate,
          })
        );
      }
    });

    this.store
      .select(selectCCAnalyticsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.cust_analytics && res.cust_analytics.status_code === 200) {
          this.loadAnalyticsData(res);
        } else if (res.error && res.error.status_code === 404) {
          this.analytics_data = null;
          // this.loader.hide();
          this.mainTemplate.next(this.noanalyticsdata);
          const dispErr = res.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });

    this.analyticsDataSubscription =
      this.shareservice.datePickerObject.subscribe((value) => {
        if (value) {
          if (value.from && value.to) {
            const fromDate: NgbDateStruct = value.from;
            const toDate: NgbDateStruct = value.to;

            this.account_dtls.startdate = `${fromDate.year}-${fromDate.month}-${fromDate.day}`;
            this.account_dtls.enddate = `${toDate.year}-${toDate.month}-${toDate.day}`;

            this.chartOptions.next({});
            this.chartData.next(null);
            this.chartType.next(null);
            // this.loader.show();
            this.mainTemplate.next(this.skeletonTemplate);
            this.store.dispatch(
              analyticsactions.fetchccanalytics({
                accountid: this.account_dtls.accountid,
                wabano: this.account_dtls.wabano,
                startdate: this.account_dtls.startdate,
                enddate: this.account_dtls.enddate,
              })
            );
          }
          if (value.action === 'Date Cleared') {
            this.account_dtls.startdate = null;
            this.account_dtls.enddate = null;
            // this.account_dtls.startdate = `${fromDate.year}-${fromDate.month}-${fromDate.day}`;
            // this.account_dtls.enddate = `${toDate.year}-${toDate.month}-${toDate.day}`;
            this.chartOptions.next({});
            this.chartData.next(null);
            this.chartType.next(null);
            // this.loader.show();
            this.mainTemplate.next(this.skeletonTemplate);
            this.store.dispatch(
              analyticsactions.fetchccanalytics({
                accountid: this.account_dtls.accountid,
                wabano: this.account_dtls.wabano,
                startdate: this.account_dtls.startdate,
                enddate: this.account_dtls.enddate,
              })
            );
          }
        }
      });
  }

  loadAnalyticsData(res: any) {
    this.analytics_data = res.cust_analytics;
    if (this.selectedDataStat) {
      const filteredLabel = this.selectedDataStat.toLowerCase(); // Replace with the label you want to filter on

      const filteredDataset = this.datasets
        .filter((t: any) => t.label.toLowerCase() === filteredLabel)
        .map((t: any) => ({
          ...t,
          data: Object.values(
            this.analytics_data.data.status_counts.day_to_day
          ).map((entry: any) => entry[t.label.toLowerCase()] || 0),
        }));

      this.barchatdata.barChartLabels = this.getlabelkeys(
        Object.keys(this.analytics_data.data.status_counts.day_to_day)
      );
      this.barchatdata.barChartData.labels = this.getlabelkeys(
        Object.keys(this.analytics_data.data.status_counts.day_to_day)
      );
      this.barchatdata.barChartData.datasets = filteredDataset;
      this.linechatdata.lineChartLabels = this.getlabelkeys(
        Object.keys(this.analytics_data.data.status_counts.day_to_day)
      );
      this.linechatdata.lineChartData.labels =
        this.linechatdata.lineChartLabels;
      this.linechatdata.lineChartData.datasets = filteredDataset;
    } else {
      this.barchatdata.barChartLabels = this.getlabelkeys(
        Object.keys(this.analytics_data.data.status_counts.day_to_day)
      );
      this.barchatdata.barChartData.labels = this.barchatdata.barChartLabels;
      this.barchatdata.barChartData.datasets = this.labelsData(
        this.analytics_data.data.status_counts.day_to_day
      );
      this.linechatdata.lineChartLabels = this.getlabelkeys(
        Object.keys(this.analytics_data.data.status_counts.day_to_day)
      );
      this.linechatdata.lineChartData.labels =
        this.linechatdata.lineChartLabels;
      this.linechatdata.lineChartData.datasets = this.labelsData(
        this.analytics_data.data.status_counts.day_to_day
      );
    }

    // this.loader.show();
    

    switch (this.selectedChart) {
      case 'Bar Graph':
        this.chartOptions.next(this.barchatdata.barChartOptions);
        this.chartData.next(this.barchatdata.barChartData);
        this.chartType.next(this.barchatdata.barChartType);
        break;
      case 'Line Graph':
        this.chartOptions.next(this.linechatdata.lineChartOptions);
        this.chartData.next(this.linechatdata.lineChartData);
        this.chartType.next(this.linechatdata.lineChartType);
        break;
    }
    // this.loader.hide();
    if (
      this.chartOptions.value &&
      this.chartData.value &&
      this.chartType.value
    ) {
      this.mainTemplate.next(this.chartTemplate);
    }
  }

  getlabelkeys(data: any) {
    const formattedDates = data.map((t: string) => {
      const parts = t.split('-');
      return parts[2] + '-' + parts[1] + '-' + parts[0];
    });
    return formattedDates;
  }

  labelsData(data: any) {
    return this.datasets.map((t: any) => ({
      ...t,
      data: Object.values(data).map(
        (entry: any) => entry[t.label.toLowerCase()] || 0
      ),
    }));
  }

  ngAfterViewInit(): void {
    this.mainTemplate.next(this.skeletonTemplate);
    this.cd.detectChanges();
  }

  onChartSwitch(event: string) {
    switch (event) {
      case 'Bar Graph':
        // if (this.selectedDataStat)
          this.chartOptions.next(this.barchatdata.barChartOptions);
        this.chartData.next(this.barchatdata.barChartData);
        this.chartType.next(this.barchatdata.barChartType);
        break;
      case 'Pie Chart':
        this.chartOptions.next(this.pieChartData.pieChartOptions);
        this.chartData.next(this.pieChartData.pieChartData);
        this.chartType.next(this.pieChartData.pieChartType);
        break;
      case 'Line Graph':
        this.chartOptions.next(this.linechatdata.lineChartOptions);
        this.chartData.next(this.linechatdata.lineChartData);
        this.chartType.next(this.linechatdata.lineChartType);
        break;
    }
  }

  // Filter chart data based on selected labels
  onStatsSwitch(event: any) {
    if (!event) {
      this.mainTemplate.next(this.skeletonTemplate);
      this.chartOptions.next({});
      this.chartData.next(null);
      this.chartType.next(null);
      this.store.dispatch(
        analyticsactions.fetchccanalytics({
          accountid: this.account_dtls.accountid,
          wabano: this.account_dtls.wabano,
          startdate: this.account_dtls.startdate,
          enddate: this.account_dtls.enddate,
        })
      );
    } else {
      this.mainTemplate.next(this.skeletonTemplate);
      this.chartOptions.next({});
      this.chartData.next(null);
      this.chartType.next(null);

      setTimeout(() => {
        const filteredLabel = event.toLowerCase(); // Replace with the label you want to filter on

        const filteredDataset = this.datasets
          .filter((t: any) => t.label.toLowerCase() === filteredLabel)
          .map((t: any) => ({
            ...t,
            data: Object.values(
              this.analytics_data.data.status_counts.day_to_day
            ).map((entry: any) => entry[t.label.toLowerCase()] || 0),
          }));

        this.barchatdata.barChartLabels = this.getlabelkeys(
          Object.keys(this.analytics_data.data.status_counts.day_to_day)
        );
        this.barchatdata.barChartData.labels = this.getlabelkeys(
          Object.keys(this.analytics_data.data.status_counts.day_to_day)
        );
        this.barchatdata.barChartData.datasets = filteredDataset;
        this.linechatdata.lineChartLabels = this.getlabelkeys(
          Object.keys(this.analytics_data.data.status_counts.day_to_day)
        );
        this.linechatdata.lineChartData.labels =
          this.linechatdata.lineChartLabels;
        this.linechatdata.lineChartData.datasets = filteredDataset;

        switch (this.selectedChart) {
          case 'Bar Graph':
            this.chartOptions.next(this.barchatdata.barChartOptions);
            this.chartData.next(this.barchatdata.barChartData);
            this.chartType.next(this.barchatdata.barChartType);
            break;
          case 'Line Graph':
            this.chartOptions.next(this.linechatdata.lineChartOptions);
            this.chartData.next(this.linechatdata.lineChartData);
            this.chartType.next(this.linechatdata.lineChartType);
            break;
        }
        if (
          this.chartOptions.value &&
          this.chartData.value &&
          this.chartType.value
        ) {
          this.mainTemplate.next(this.chartTemplate);
        }
      }, 1000);
    }
  }

  refreshAnalytics() {
    this.mainTemplate.next(this.skeletonTemplate);
    this.store.dispatch(
      analyticsactions.fetchccanalytics({
        accountid: this.account_dtls.accountid,
        wabano: this.account_dtls.wabano,
        startdate: this.account_dtls.startdate,
        enddate: this.account_dtls.enddate,
      })
    );
  }

  ngOnDestroy() {
    this.mainTemplate.unsubscribe();
    this.analyticsDataSubscription.unsubscribe();
    this.shareservice.datePickerObject.next(null);
    this.channelSub.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
