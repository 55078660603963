import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AlertService } from 'src/app/alert/alert.service';
import { SelectionModel } from '@angular/cdk/collections';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { selectCampaignActionData } from 'src/app/customer/campaign-manager/store/campaign.selectors';
import { CampaignDetails } from 'src/app/appdata/campaign.model';
import * as campaignactions from 'src/app/customer/campaign-manager/store/campaign.actions';
import { skeleton_data } from 'src/app/customer/whatsapp-preview/whatsapp-preview.data';
import {
  CampaignService,
  CampaignVariables,
} from 'src/app/services/campaign.service';
import { SharedService } from 'src/app/services/shared.service';
import { Router } from '@angular/router';
import {
  AuthUserData,
  ChannelCredentials,
  RbacPolicies,
  SsoLoginData,
} from 'src/app/appdata/auth.model';
import {
  selectProfilesDtlsData,
  selectSsoProfilesDtlsData,
} from 'src/app/store/common.selectors';
import { LoaderService } from 'src/app/services/loader.service';
import { MatTableDataSource } from '@angular/material/table';
import { SsoAuthUserState } from 'src/app/store/common.reducer';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-campaignsetup',
  templateUrl: './campaignlist.component.html',
  styleUrls: ['./campaignlist.component.scss'],
})
export class CampaignListComponent implements OnInit, OnDestroy, AfterViewInit {
  displayTemplate!: TemplateRef<any>;
  selectedBA: any;
  activetab = 1;
  campaignsList: MatTableDataSource<CampaignDetails> =
    new MatTableDataSource<CampaignDetails>([]);
  totalCampaignsData: any;
  totalCampaigns: number;
  channel_credentails: ChannelCredentials;
  page = 1;
  pageSize = 10;
  campaignStatus = [
    'Completed',
    'Inprogress',
    'Paused',
    'Scheduled',
    'Draft',
    'Failed',
  ];
  campaignDataSubscription: Subscription;
  channelSub: Subscription;
  selection = new SelectionModel<CampaignDetails>(true, []);
  destroy$: Subject<boolean> = new Subject<boolean>();
  campaignCategory = [
    { id: 1, name: 'Promotional' },
    { id: 2, name: 'Transactional' },
  ];
  campaignType = [
    { id: 1, name: 'Bulk' },
    { id: 2, name: 'Single' },
  ];
  filterCriteria: any = {
    campaign_category: null,
    campaign_type: null,
    campaign_tag: null,
    campaign_profile: null,
    campaign_status: null,
  };
  campaignTags: any = [];
  campaignProfile: any = [];
  skeletonData = skeleton_data;
  selectedCampaignStatus: string;
  userDetails: AuthUserData | any;
  searchData: string = null;
  permissions: RbacPolicies;
  isFirst: boolean = true;
  isLoading: boolean = true;
  searching: boolean = false;
  searchInput: boolean = false;
  deleteItem: any = null;
  campaign_payload_var: CampaignVariables = {
    accountid: null,
    channel: null,
    bspid: null,
    wabano: null,
    campaign_name: null,
    page: this.page,
    size: this.pageSize,
    category: null,
    type: null,
    tag: null,
    profile: null,
    status: null,
    startdate: null,
    enddate: null,
  };

  displayedColumns: string[] = [
    'name',
    'category',
    'campaigntype',
    'template',
    'status',
    'tags',
    'starttime',
    'endtime',
    'scheduled',
    // 'actions',
  ];

  @ViewChild('noCampaigns') private noCampaigns!: TemplateRef<any>;
  @ViewChild('datawaiting') private datawaiting!: TemplateRef<any>;
  @ViewChild('showCampaigns') private showCampaigns!: TemplateRef<any>;

  constructor(
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private alertMsg: AlertService,
    private shareservice: SharedService,
    public loader: LoaderService,
    private router: Router,
    private readonly store: Store,
    private campaignservice: CampaignService
  ) {}

  ngOnInit(): void {
    this.profileDataSub();
    this.channelDataSub();
    this.campaignActionsSub();
    this.campaignDateSub();
  }

  profileDataSub() {
    if (environment.sso) {
      this.store
        .select(selectSsoProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
            this.permissions = res.loggedInUserDetails.rbac.rbac_policies;
          }
        });
    } else {
      this.store
        .select(selectProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          if (res.loggedInUserDetails as AuthUserData) {
            this.userDetails = res.loggedInUserDetails as AuthUserData;
            this.permissions = this.userDetails.profile.acl.rbac.rbac_policies;
          }
        });
    }
  }

  channelDataSub() {
    this.channelSub = this.shareservice.selectedBA$.subscribe((value: any) => {
      if (value) {
        this.channel_credentails = value.channel_credentials;
        this.campaign_payload_var = JSON.parse(
          JSON.stringify(this.campaign_payload_var)
        );
        this.campaign_payload_var.accountid =
          value.channel_credentials.account_id;
        this.campaign_payload_var.bspid = value.channel_credentials.bsp_id;
        this.campaign_payload_var.wabano =
          value.channel_credentials.waba_number;
        this.campaign_payload_var.channel =
          value.channel_credentials.channel_name.toLowerCase();

        if (!this.isFirst) {
          this.store.dispatch(
            campaignactions.fetchcampaignslist({
              payload: this.campaign_payload_var,
            })
          );
        }
        this.isFirst = false;
      }
    });
  }

  campaignActionsSub() {
    this.store
      .select(selectCampaignActionData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.campaignList && res.campaignList.status_code === 200) {
          this.loadCampaignList(res);
        }

        if (
          res.campaignUpdateRes &&
          res.campaignUpdateRes.status_code === 200
        ) {
          this.loadUpdateAction(res);
        }

        if (
          res.deleteCampaignRes &&
          res.deleteCampaignRes.status_code === 200
        ) {
          this.modalService.dismissAll();
          this.deleteItem = null;
          this.store.dispatch(
            campaignactions.fetchcampaignslist({
              payload: this.campaign_payload_var,
            })
          );
        }

        if (res.campaign_error) {
          this.loader.hide();
          const dispErr = res.campaign_error.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        } else if (res.error) {
          this.handleCampaignError(res);
        }
      });
  }

  handleCampaignError(res: any) {
    this.modalService.dismissAll();
    this.loader.hide();
    this.isLoading = false;
    if (res.error && res.error.status_code === 404) {
      this.campaignsList = new MatTableDataSource<CampaignDetails>([]);
      this.totalCampaigns = 0;
      if (!this.searching) {
        const dispErr = res.error.message;
        this.alertMsg.alertDanger('Error', dispErr);
      }
    } else if (res.error.message) {
      const dispErr = res.error.message;
      this.alertMsg.alertDanger('Error', dispErr);
    }
  }

  loadCampaignList(res: any) {
    this.isLoading = false;
    this.totalCampaignsData = res;
    this.campaignsList = res.campaignList.data;
    this.totalCampaigns = res.campaignList.count;
    this.loader.hide();
    if (
      (this.campaign_payload_var.startdate &&
        this.campaign_payload_var.enddate) ||
      !this.checkFilteredItems()
    ) {
      this.displayTemplate = this.showCampaigns;
    } else {
      this.displayTemplate =
        this.totalCampaigns > 0 ? this.showCampaigns : this.noCampaigns;
    }
  }

  loadUpdateAction(res: any) {
    this.alertMsg.alertSuccess(
      'Campaign Updated',
      res.campaignUpdateRes.message
    );
    this.loader.show();
    this.store.dispatch(
      campaignactions.fetchcampaignslist({
        payload: this.campaign_payload_var,
      })
    );
  }

  campaignDateSub() {
    this.shareservice.customDateAction.next(7);
    this.campaignDataSubscription =
      this.shareservice.datePickerObject.subscribe((value) => {
        if (value) {
          if (value.from && value.to) {
            const fromDate: NgbDateStruct = value.from;
            const toDate: NgbDateStruct = value.to;

            this.campaign_payload_var = JSON.parse(
              JSON.stringify(this.campaign_payload_var)
            );
            this.campaign_payload_var.page = null;
            this.campaign_payload_var.size = null;
            this.campaign_payload_var.startdate = `${fromDate.year}-${fromDate.month}-${fromDate.day}`;
            this.campaign_payload_var.enddate = `${toDate.year}-${toDate.month}-${toDate.day}`;

            if (value.requestfrom === 'campaignListDetails') {
              this.store.dispatch(
                campaignactions.fetchcampaignslist({
                  payload: this.campaign_payload_var,
                })
              );
            }
          }
          if (value.action === 'Date Cleared') {
            this.campaign_payload_var = JSON.parse(
              JSON.stringify(this.campaign_payload_var)
            );
            this.campaign_payload_var.startdate = null;
            this.campaign_payload_var.enddate = null;
            this.campaign_payload_var.page = 1;
            this.campaign_payload_var.size = 10;
            this.page = 1;
            this.pageSize = 10;
            if (value.requestfrom === 'campaignListDetails') {
              this.store.dispatch(
                campaignactions.fetchcampaignslist({
                  payload: this.campaign_payload_var,
                })
              );
            }
          }
        }
      });
  }

  searchName(data: string) {
    if (!this.searchInput && data.trim() && data && data.length > 2) {
      this.searching = true;
      this.campaign_payload_var = JSON.parse(
        JSON.stringify(this.campaign_payload_var)
      );
      this.campaign_payload_var.campaign_name = data.trim();
      this.store.dispatch(
        campaignactions.fetchcampaignslist({
          payload: this.campaign_payload_var,
        })
      );
    } else {
      this.searching = false;
    }
  }

  onSearchNameChange(event: any) {
    this.searchInput = this.shareservice.whiteSpaceValidator(event);
    if (!event) {
      this.searching = false;
      this.campaign_payload_var = JSON.parse(
        JSON.stringify(this.campaign_payload_var)
      );
      this.campaign_payload_var.campaign_name = null;
      this.store.dispatch(
        campaignactions.fetchcampaignslist({
          payload: this.campaign_payload_var,
        })
      );
    } else {
      this.searchName(event);
    }
  }

  getTemplate(data: any) {
    let temp_data = JSON.parse(data);
    if (typeof temp_data === 'string') {
      temp_data = JSON.parse(temp_data);
    }
    return temp_data;
  }

  refreshCampaignsList() {
    this.isLoading = true;
    this.campaignsList = new MatTableDataSource<CampaignDetails>([]);
    this.totalCampaigns = 0;

    this.store.dispatch(
      campaignactions.fetchcampaignslist({
        payload: this.campaign_payload_var,
      })
    );
  }

  ngAfterViewInit(): void {
    this.displayTemplate = this.showCampaigns;
    this.cd.detectChanges();
  }

  clearFilter(action: string) {
    this.campaign_payload_var = JSON.parse(
      JSON.stringify(this.campaign_payload_var)
    );
    if (action === 'category') {
      this.campaign_payload_var.category = null;
      this.filterCriteria.campaign_category = null;
    } else if (action === 'type') {
      this.campaign_payload_var.type = null;
      this.filterCriteria.campaign_type = null;
    } else if (action === 'tag') {
      this.campaign_payload_var.tag = null;
      this.filterCriteria.campaign_tag = null;
    } else if (action === 'profile') {
      this.campaign_payload_var.profile = null;
      this.filterCriteria.campaign_profile = null;
    } else if (action === 'status') {
      this.campaign_payload_var.status = null;
      this.filterCriteria.campaign_status = null;
    } else if (action === 'clearfilter') {
      this.campaign_payload_var.category = null;
      this.campaign_payload_var.type = null;
      this.campaign_payload_var.tag = null;
      this.campaign_payload_var.profile = null;
      this.campaign_payload_var.status = null;
      this.filterCriteria.campaign_category = null;
      this.filterCriteria.campaign_type = null;
      this.filterCriteria.campaign_tag = null;
      this.filterCriteria.campaign_profile = null;
      this.filterCriteria.campaign_status = null;
    }

    this.checkFilteredItems();
    this.store.dispatch(
      campaignactions.fetchcampaignslist({
        payload: this.campaign_payload_var,
      })
    );
  }

  checkFilteredItems() {
    const obj = this.filterCriteria;

    const isNullish = Object.values(obj).every((value) => {
      if (value === null) {
        return true;
      }

      return false;
    });

    return isNullish;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.campaignsList.data?.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.campaignsList.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: CampaignDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }

  setupNewCampaign() {
    this.router.navigate([
      this.shareservice.getRoutingUrl(),
      this.userDetails.profile.id,
      'campaign-manager',
      'create',
    ]);
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }

  gotoListing() {
    if (
      (this.campaign_payload_var.startdate &&
        this.campaign_payload_var.enddate) ||
      !this.checkFilteredItems()
    ) {
      this.displayTemplate = this.showCampaigns;
    } else {
      this.displayTemplate =
        this.totalCampaigns > 0 ? this.showCampaigns : this.noCampaigns;
    }
  }

  viewCampaign(data: any) {
    if (this.permissions.campagin_management.can_view) {
      if (
        data.status === 'completed' ||
        (data.status === 'inprogress' &&
          data.is_preprocessed &&
          data.is_start_campaign) ||
        (data.status === 'paused' && data.is_preprocessed)
      ) {
        this.router.navigate([
          this.shareservice.getRoutingUrl(),
          this.userDetails.profile.id,
          `campaign-manager`,
          data.id,
          `view`,
        ]);
      } else if (
        (data.status === 'inprogress' && !data.is_preprocessed) ||
        (data.status === 'paused' && !data.is_preprocessed)
      ) {
        this.alertMsg.alertWarning(
          'Warning',
          'The campaign is currently in progress. Please await to view the analytics.'
        );
      } else if (
        (data.status === 'inprogress' &&
          data.is_preprocessed &&
          !data.is_start_campaign) ||
        (data.status === 'paused' && !data.is_preprocessed)
      ) {
        this.alertMsg.alertWarning(
          'Warning',
          'You have not started the campaign yet.'
        );
      } else {
        return;
      }
    }
  }

  editCampaign(item: CampaignDetails) {
    this.store.dispatch(campaignactions.editCampaignData({ data: item }));
    this.router.navigate([
      this.shareservice.getRoutingUrl(),
      this.userDetails.profile.id,
      `campaign-manager`,
      item.id,
      `edit`,
    ]);
  }

  openModal(content: TemplateRef<string>, data: string, item?: any) {
    const sizes: any = {
      viewTemplate: 'lg',
      addSample: 'xl',
      deleteTemplate: 'md',
    };

    if (item) {
      this.deleteItem = item;
    }

    this.modalService.open(content, {
      centered: true,
      scrollable: true,
      backdrop: 'static',
      size: sizes[data] || 'md',
    });
  }

  onPageEvent(event: any) {
    this.page = event;
    this.campaign_payload_var = JSON.parse(
      JSON.stringify(this.campaign_payload_var)
    );
    this.campaign_payload_var.page = this.page;
    this.campaign_payload_var.size = this.pageSize;

    this.store.dispatch(
      campaignactions.fetchcampaignslist({
        payload: this.campaign_payload_var,
      })
    );
  }

  applyFilter() {
    this.campaign_payload_var = JSON.parse(
      JSON.stringify(this.campaign_payload_var)
    );
    this.campaign_payload_var.status =
      this.filterCriteria.campaign_status?.toLowerCase();
    this.campaign_payload_var.category =
      this.filterCriteria.campaign_category?.toLowerCase();
    this.campaign_payload_var.type =
      this.filterCriteria.campaign_type?.toLowerCase();
    this.campaign_payload_var.tag =
      this.filterCriteria.campaign_tag?.toLowerCase();
    this.campaign_payload_var.page = 1;
    this.campaign_payload_var.size = 10;
    this.page = 1;
    this.pageSize = 10;
    this.store.dispatch(
      campaignactions.fetchcampaignslist({
        payload: this.campaign_payload_var,
      })
    );
  }

  runCampaign(item: any) {
    const pauseCampaign_payload = {
      id: item.id,
      is_start_campaign: true,
    };
    this.store.dispatch(
      campaignactions.updateCampaignAction.updateCampaign({
        datapayload: pauseCampaign_payload,
      })
    );
  }

  PauseCampaign(item: any) {
    const pauseCampaign_payload = {
      id: item.id,
      status: 'paused',
    };
    this.store.dispatch(
      campaignactions.updateCampaignAction.updateCampaign({
        datapayload: pauseCampaign_payload,
      })
    );
  }

  ResumeCampaign(item: any) {
    const resumeCampaign_payload = {
      id: item.id,
      status: 'resume',
    };
    this.store.dispatch(
      campaignactions.updateCampaignAction.updateCampaign({
        datapayload: resumeCampaign_payload,
      })
    );
  }

  downloadData() {
    this.campaign_payload_var = JSON.parse(
      JSON.stringify(this.campaign_payload_var)
    );
    const Downloadpayload = { ...this.campaign_payload_var };

    Downloadpayload.size = this.totalCampaigns;
    Downloadpayload.page = 1;

    this.campaignservice.fetchcampaigns(Downloadpayload).subscribe({
      next: (response) => {
        if (response.status_code === 200) {
          this.downloadCsvFile(response.data);
        }
      },
      error: (error) => {
        if (error.message) {
          const dispErr = error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      },
    });
  }

  downloadCsvFile(data: any) {
    const csvData: any = JSON.parse(JSON.stringify(data));
    let headers =
      'Name,Category, Campaign Type, Template,Status,Tags,Start Time,End Time,Scheduled Time\n';
    const timezone = ' (GMT+5:30 IST)';

    csvData.forEach((item: any) => {
      const name = item.name;
      const category = item.category;
      const campaignType = item.is_bulk_campaign ? 'Bulk' : 'Single';
      const template = item.template_payload
        ? this.getTemplate(item.template_payload).template_name
        : 'N/A';
      const status = item.status;
      const tags = item.tags ? this.getTags(JSON.parse(item.tags)) : 'N/A';
      const startTime = item.started_on
        ? new Date(item.started_on).toLocaleString() + timezone
        : 'N/A';
      const endTime = item.ended_on
        ? new Date(item.ended_on).toLocaleString() + timezone
        : 'N/A';
      const scheduledTime = item.scheduled_at
        ? new Date(item.scheduled_at).toLocaleString() + timezone
        : 'N/A';

      headers += `"${name}","${category}","${campaignType}","${template}","${status}","${tags}","${startTime}","${endTime}","${scheduledTime}"\n`;
    });
    this.shareservice.exportFile(
      headers,
      'text/csv;charset=utf-8;',
      'campaigndata'
    );
  }

  onDeleteCampaign() {
    const payload = [this.deleteItem.id];
    this.store.dispatch(
      campaignactions.deleteCampaignAction.deleteCampaign({
        datapayload: payload,
      })
    );
  }

  getTags(data: any) {
    let text = '';
    for (let i = 0; i < data.length; i++) {
      text += data[i];

      // Add a comma and space after each element except the last one
      if (i < data.length - 1) {
        text += ', ';
      }
    }
    return text;
  }

  // Function to show Campaign Status Related Tooltips
  getCampaignTooltipStatus(status: string) {
    switch (status) {
      case 'completed': {
        return 'The campaign on the platform has concluded successfully.';
      }
      case 'inprogress': {
        return 'Campaign is in progress. Please wait while the process completes.';
      }
      case 'draft': {
        return 'This campaign is currently in draft mode. Changes can still be made before finalizing';
      }
      case 'failed': {
        return 'Failed to run this campaign. Please check your inputs, consents and try again.';
      }
      default: {
        break;
      }
    }
  }

  ngOnDestroy() {
    this.channelSub.unsubscribe();
    this.campaignDataSubscription.unsubscribe();
    this.store.dispatch(campaignactions.resetCampaignData());
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.shareservice.datePickerObject.next(null);
    this.shareservice.customDateAction.next(null);
  }
}
