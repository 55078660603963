/* eslint-disable no-useless-escape */
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CountryData } from 'src/app/appdata/countries.data';
import {
  MediaDriveVariables,
  SharedService,
} from 'src/app/services/shared.service';
import { AlertService } from 'src/app/alert/alert.service';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';
import { selectCampaignActionData } from '../store/campaign.selectors';
import { MatStepper } from '@angular/material/stepper';
import { selectActionsData } from '../../templates/store/template.selectors';
import { Template } from 'src/app/appdata/template.model';
import * as templateactions from 'src/app/customer/templates/store/template.actions';
import * as campaignactions from 'src/app/customer/campaign-manager/store/campaign.actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomValidators } from 'src/app/services/CustomValidators';
import {
  selectCommonDataDtls,
  selectProfilesDtlsData,
  selectSsoProfilesDtlsData,
} from 'src/app/store/common.selectors';
import { MediaFile } from 'src/app/appdata/fileserver.model';
import { SelectionModel } from '@angular/cdk/collections';
import * as commonactions from 'src/app/store/common.actions';
import {
  TemplateService,
  TemplateVariables,
} from 'src/app/services/template.service';
import { Datum } from 'src/app/appdata/channeldata.model';
import * as ct from 'countries-and-timezones';
import { MatChipInputEvent, MatChipEditedEvent } from '@angular/material/chips';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { TitleCasePipe } from '@angular/common';
import { CommonService } from 'src/app/services/common.service';
import { LoaderService } from 'src/app/services/loader.service';
import {
  CampaignService,
  CampaignVariables,
} from 'src/app/services/campaign.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-campaignsetup',
  templateUrl: './campaignsetup.component.html',
  styleUrls: ['./campaignsetup.component.scss'],
})
export class CampaignsetupComponent implements OnInit, OnDestroy {
  campaignsetup: FormGroup;
  campaignUploadCSV: FormGroup;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  addOnBlur = true;
  selectedDialCode: any;
  countries: Array<any> = this.countrydata.CountryDialCodes;
  isSaveToDraft = false;
  templatesLoading = false;
  showRefreshBtn = true;
  templatesList$ = new BehaviorSubject<Template[]>([]);
  selectedTemplate: any;
  editCampaignPrvsName: any;
  selectedCampaignData: any;
  refresh_interval: number;
  file: File;
  channel_credentails: Datum;
  mediaFiles$ = new BehaviorSubject<MediaFile[]>([]);
  preprocessing_data: any;
  tempCategory: Array<any> = [];
  timeZones: Array<any> = [];
  page = 1;
  size = 10;
  charCount = 0;
  maxCharCount = 30;
  campaignTags: Array<string> = [];
  templateData: any;
  totalTemplateList: number = 0;
  totalmediafiles: number = 0;
  isLinear = true;
  isAnalysing = false;
  upload_action: string;
  file_urls: any = {
    header: null,
    bulkupload: null,
  };
  cmpCategory = [
    { id: 1, name: 'Promotional' },
    { id: 2, name: 'Transactional' },
  ];
  fileselection = new SelectionModel<MediaFile>(true, []);
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Output() viewCampaignlist = new EventEmitter<boolean>(false);
  channelSub: Subscription;
  dateModel: Date = new Date();
  searchData: any;
  stringDateModel: string = new Date().toString();
  userDetails: any;
  campaignNameExists: boolean;
  urlText: boolean = false;

  account_dtls: any = {
    account_id: null,
    bsp_id: null,
    waba_no: null,
    channel: null,
    createdby: null,
  };

  temp_payload_var: TemplateVariables = {
    accountid: null,
    bspid: null,
    wabano: null,
    page: this.page,
    size: this.size,
    category: null,
    type: null,
    status: null,
    template_name: null,
    template_lang: null,
    startdate: null,
    enddate: null,
  };

  mediadrive_payload_var: MediaDriveVariables = {
    accountid: null,
    channel: null,
    bspid: null,
    wabano: null,
    page: this.page,
    size: this.size,
    filetype: null,
    startdate: null,
    enddate: null,
  };
  minDate: Date | undefined = new Date();

  @ViewChild('stepper') private stepper: MatStepper;
  @ViewChild('mediaLibrary') private mediaLibrary: NgbModal;

  constructor(
    private fb: FormBuilder,
    private alertMsg: AlertService,
    private cv: CustomValidators,
    private activatedRoute: ActivatedRoute,
    private readonly store: Store,
    private titleCasePipe: TitleCasePipe,
    private router: Router,
    private countrydata: CountryData,
    public loader: LoaderService,
    private modalService: NgbModal,
    private shareservice: SharedService,
    public templateservice: TemplateService,
    public commonservice: CommonService,
    private campaignService: CampaignService
  ) {}

  ngOnInit(): void {
    this.initiateChannelSub();
    this.checkEditCampaign();
    this.initiateProfileSub();
    this.initiateForms();
    this.initiateEditCampaignSub();
    this.getTemplateList();
    this.initiateCampaignSub();
    this.initiateUploadSub();
    this.initiateCampaignSetupSub();
  }

  get headervariablesarray(): FormArray {
    return this.campaignsetup.get('headerVariables') as FormArray;
  }

  get bodyvariablesarray(): FormArray {
    return this.campaignsetup.get('bodyVariables') as FormArray;
  }

  get dynamicvariablesarray(): FormArray {
    return this.campaignsetup.get('dynamicBtnVariable') as FormArray;
  }

  initiateForms() {
    this.campaignsetup = this.fb.group({
      contacttype: ['singlecontact'],
      countrycode: [null, Validators.required],
      contactno: [
        null,
        [Validators.required, Validators.pattern(this.cv.contactRegex)],
      ],
      campaignName: [
        null,
        [Validators.required, Validators.pattern(this.cv.checkSpacesRegex)],
      ],
      camapaignCategory: [null, Validators.required],
      templateCategory: [null, Validators.required],
      chooseTemplate: [null, Validators.required],
      activeEndDate: [
        new Date(),
        { validators: [Validators.required, DateTimeValidator] },
      ],
      headerMedia: [null],
      headerVariables: this.fb.array([]),
      bodyVariables: this.fb.array([]),
      dynamicBtnVariable: this.fb.array([]),
    });

    this.campaignsetup.get('campaignName').valueChanges.subscribe((t) => {
      this.charCount = t ? t.length : 0;
    });

    this.campaignUploadCSV = this.fb.group({
      nonConsent: [false],
      scheduleCampaign: [false],
      scheduledcountry: [null],
      scheduledtimezone: [null],
      scheduleDateTime: [null],
      mediaURL: [null],
    });
  }

  setDailCode(value: any) {
    if (value && value === 'singlecontact') {
      this.campaignsetup.get('countrycode').setValue(this.selectedDialCode);
      this.campaignsetup
        .get('contactno')
        .addValidators([
          Validators.required,
          Validators.pattern(this.cv.contactRegex),
        ]);
      this.campaignsetup.get('contactno').updateValueAndValidity();
      this.campaignsetup.get('countrycode').updateValueAndValidity();
    } else {
      this.campaignsetup.get('contactno').clearValidators();
      this.campaignsetup.get('contactno').updateValueAndValidity();

      this.campaignsetup.get('countrycode').setValue(null);
      this.campaignsetup.get('countrycode').clearValidators();
      this.campaignsetup.get('countrycode').updateValueAndValidity();
    }
  }

  initiateEditCampaignSub() {
    this.store
      .select(selectCampaignActionData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.campaign_data?.individual_data) {
          this.loader.hide();
          const sel_campaign = res.campaign_data.individual_data.data;
          this.selectedCampaignData = res.campaign_data.individual_data.data;
          this.getTemplates(
            this.titleCasePipe.transform(
              JSON.parse(sel_campaign.template_payload).category
            )
          );

          const choosedTemp = JSON.parse(
            sel_campaign.template_payload
          ).template_name;

          if (choosedTemp) {
            this.temp_payload_var = JSON.parse(
              JSON.stringify(this.temp_payload_var)
            );
            const payoad: any = { ...this.temp_payload_var };
            payoad.template_name = choosedTemp;
            payoad.status = 'approved';
            this.templateservice.fetchtemplates(payoad).subscribe({
              next: (res) => {
                if (res.status_code === 200) {
                  this.setTempData(res.data.templates);
                }
              },
              error: (err) => {
                this.loader.hide();
                const dispErr = err.message;
                this.alertMsg.alertDanger('Error', dispErr);
              },
            });
          }

          this.campaignsetup.patchValue({
            contacttype: this.getContactType(sel_campaign),
            countrycode: sel_campaign.country_code?.substr(1),
            campaignName: sel_campaign.name,
            camapaignCategory: this.titleCasePipe.transform(
              sel_campaign.category
            ),
            templateCategory: JSON.parse(sel_campaign.template_payload)
              .category,
            chooseTemplate: this.getSelectedTemplate(
              sel_campaign.template_payload
            ),
          });
          this.campaignTags = JSON.parse(sel_campaign.tags);
          this.onChooseTemplate(JSON.parse(sel_campaign.template_payload));
          this.campaignUploadCSV.patchValue({
            nonConsent: sel_campaign.is_send_to_non_consent_recipients,
            scheduleCampaign: sel_campaign.scheduled_at ? true : false,
            scheduledcountry: sel_campaign.scheduled_country
              ? sel_campaign.scheduled_country
              : null,
            scheduledtimezone: sel_campaign.scheduled_timezone
              ? sel_campaign.scheduled_timezone
              : null,
            scheduleDateTime: sel_campaign.scheduled_at
              ? new Date(sel_campaign.scheduled_at)
              : null,
            mediaURL: sel_campaign.bulk_upload_url,
          });
          if (sel_campaign.bulk_upload_url) {
            const url = sel_campaign.bulk_upload_url;
            const payload: any = {
              url: url,
              file_name: url.split('/').pop(),
            };
            this.file_urls.bulkupload = payload;
          }
          this.editCampaignPrvsName =
            this.campaignsetup.get('campaignName').value;
        } else if (res.error) {
          this.loader.hide();
        }
      });
  }

  setTempData(tempdata: any) {
    const data = [...this.templatesList$.getValue(), ...tempdata];
    const uniqueData = data.filter(
      (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
    );
    this.templatesList$.next([...uniqueData]);
  }

  onSearch(event: any) {
    this.temp_payload_var = JSON.parse(JSON.stringify(this.temp_payload_var));
    this.temp_payload_var.page = 1;
    if (event.term && event.term.length > 0) {
      this.temp_payload_var.template_name = event.term;
    } else {
      this.temp_payload_var.template_name = null;
    }
    this.getTemplates(this.temp_payload_var.category);
  }

  getTemplateList() {
    this.store
      .select(selectActionsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.templateList) {
          // this.templatesList$.next([
          //   ...this.templatesList$.getValue(),
          //   ...res.templateList.data.templates,
          // ]);
          this.setTempData(res.templateList.data.templates);
          this.totalTemplateList = res.templateList.data.count;
        } else if (res.error) {
          this.loader.hide();
          const dispErr = res.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });
  }

  getContactType(data: any) {
    if (data.is_bulk_campaign) {
      return 'multiplecontacts';
    } else {
      return 'singlecontact';
    }
  }

  getSelectedTemplate(data: any) {
    const sel_template = JSON.parse(data);
    return sel_template.id;
  }

  initiateChannelSub() {
    this.channelSub = this.shareservice.selectedBA$.subscribe((value: any) => {
      if (value) {
        this.account_dtls.account_id = value.channel_credentials.account_id;
        this.account_dtls.bsp_id = value.channel_credentials.bsp_id;
        this.account_dtls.waba_no = value.channel_credentials.waba_number;
        this.account_dtls.channel =
          value.channel_credentials.channel_name.toLowerCase();
        this.channel_credentails = value.channel_credentials;
        this.temp_payload_var.accountid = this.account_dtls.account_id;
        this.temp_payload_var.bspid = this.account_dtls.bsp_id;
        this.temp_payload_var.wabano = value.channel_credentials.waba_number;
        this.mediadrive_payload_var.accountid = this.account_dtls.account_id;
        this.mediadrive_payload_var.bspid = this.account_dtls.bsp_id;
        this.mediadrive_payload_var.wabano =
          value.channel_credentials.waba_number;
        this.mediadrive_payload_var.channel =
          value.channel_credentials.channel_name.toLowerCase();
      }
    });
  }

  checkEditCampaign() {
    if (this.router.url.includes('edit')) {
      this.activatedRoute.params.subscribe((params: Params) => {
        const campaign_id = params['id'];
        this.loader.show();
        this.store.dispatch(
          campaignactions.FetchCampaignDetails({
            id: campaign_id,
            accountid: this.account_dtls.account_id,
          })
        );
      });
    } else {
      this.selectedDialCode = this.countries.find((t) => t.dialCode === '91');
    }
  }

  initiateProfileSub() {
    if (environment.sso) {
      this.store
        .select(selectSsoProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
            this.account_dtls.createdby =
              res.loggedInUserDetails.profile.first_name +
              ' ' +
              res.loggedInUserDetails.profile.last_name;
          }
        });
    } else {
      this.store
        .select(selectProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          if (res.loggedInUserDetails) {
            this.account_dtls.createdby =
              res.loggedInUserDetails.profile.first_name +
              ' ' +
              res.loggedInUserDetails.profile.last_name;
            this.userDetails = res.loggedInUserDetails;
          }
        });
    }
  }

  initiateCampaignSub() {
    this.store
      .select(selectCampaignActionData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.campaignRes) {
          this.loader.hide();
          if (
            res.campaignRes.status_code === 200 ||
            res.campaignRes.status_code === 201
          ) {
            this.templateData = res.campaignRes.message;
            this.file_urls.header = null;
            this.file_urls.bulkupload = null;
            this.file = null;
            if (this.stepper) {
              this.stepper.next();
            }
            this.isSaveToDraft = false;
            this.selectedTemplate = null;
            this.selectedCampaignData = null;
            this.store.dispatch(commonactions.resetcommondata());
          } else if (
            res.campaignRes.status_code === 409 ||
            res.campaignRes.status_code === 422
          ) {
            this.alertMsg.alertWarning('Warning!', res.campaignRes.message);
            this.store.dispatch(commonactions.resetcommondata());
          }
        } else if (
          res.preprocessingRes &&
          res.preprocessingRes.status_code === 201
        ) {
          this.isAnalysing = true;
          this.selectedCampaignData = res.preprocessingRes.data;
          setTimeout(() => {
            this.store.dispatch(
              campaignactions.FetchCampaignValidation({
                id: res.preprocessingRes.data.id,
              })
            );
          }, 3000);
        } else if (
          res.preprocessingRes &&
          res.preprocessingRes.status_code === 409
        ) {
          this.alertMsg.alertDanger('Warning', res.preprocessingRes.message);
        } else if (
          res.campaignSaveRes &&
          res.campaignSaveRes.status_code === 201
        ) {
          this.loader.hide();
          if (res.campaignSaveRes.data.is_drafted) {
            this.gotoListing();
            this.alertMsg.alertSuccess('Success', res.campaignSaveRes.message);
            this.file_urls.header = null;
            this.file_urls.bulkupload = null;
            this.file = null;
            this.isSaveToDraft = false;
            this.selectedTemplate = null;
            this.selectedCampaignData = null;
            this.store.dispatch(commonactions.resetcommondata());
          }
        } else if (
          res.campaignSaveRes &&
          res.campaignSaveRes.status_code === 409
        ) {
          this.alertMsg.alertDanger('Warning', res.campaignSaveRes.message);
        } else if (
          res.campaignValidationDtls &&
          res.campaignValidationDtls.status_code === 200
        ) {
          this.preprocessing_data = res.campaignValidationDtls.data;
          // if (!res.campaignValidationDtls.is_retry) {
          //   this.isAnalysing = false;
          //   this.showRefreshBtn = false;
          // } else {
          //   this.isAnalysing = false;
          //   setTimeout(() => {
          //     this.showRefreshBtn = true;
          //   }, 20000);
          // }

          this.showRefreshBtn = false;
          if (res.campaignValidationDtls.time_interval_in_secs) {
            this.refresh_interval =
              res.campaignValidationDtls.time_interval_in_secs;
            this.isAnalysing = false;
            setTimeout(() => {
              this.showRefreshBtn = true;
            }, res.campaignValidationDtls.time_interval_in_secs * 1000);
          }
        } else if (res.campaignUpdateRes) {
          this.performCampaignUpdate(res);
        } else if (res.error) {
          this.handleCampaignErrors(res);
        }
      });
  }

  handleCampaignErrors(res: any) {
    this.loader.hide();
    if (res.error.message) {
      this.alertMsg.alertDanger('Error', res.error.message);
      this.isAnalysing = false;
      this.file_urls.header = null;
      this.file_urls.bulkupload = null;
      this.file = null;
    } else {
      const dispErr = res.error.error.message;
      this.alertMsg.alertDanger('Error', dispErr);
    }
    this.store.dispatch(commonactions.resetcommondata());
  }

  performCampaignUpdate(res: any) {
    this.loader.hide();
    if (
      res.campaignUpdateRes.status_code === 200 ||
      res.campaignUpdateRes.status_code === 201
    ) {
      if (this.selectedCampaignData) {
        if (this.file_urls.bulkupload) {
          this.isAnalysing = true;
          this.store.dispatch(
            campaignactions.FetchCampaignValidation({
              id: res.campaignUpdateRes.data.id,
            })
          );
        }
        if (this.isSaveToDraft) {
          this.gotoListing();
          this.alertMsg.alertSuccess('Success', res.campaignUpdateRes.message);
        }
      } else if (!this.isAnalysing && this.stepper) {
        this.templateData = res.campaignUpdateRes.message;
        this.file_urls.header = null;
        this.file_urls.bulkupload = null;
        this.file = null;
        this.stepper.next();
        this.isSaveToDraft = false;
        this.selectedTemplate = null;
        this.selectedCampaignData = null;
        this.store.dispatch(commonactions.resetcommondata());
      }
    }
  }

  initiateUploadSub() {
    this.store
      .select(selectCommonDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.uploadRes) {
          if (res.uploadRes.status_code === 200) {
            if (this.stepper?.selectedIndex === 0) {
              this.file_urls.header = res.uploadRes.data?.fetchfiles
                ? res.uploadRes.data?.fetchfiles[0]
                : res.uploadRes.data;
              this.insertMedia(this.file_urls.header);
            }
            if (this.stepper?.selectedIndex === 1) {
              this.file_urls.bulkupload = res.uploadRes.data;
              this.sendPreProcessing(this.file_urls.bulkupload);
            }
          }
        } else if (res.mediaServerFiles) {
          if (res.mediaServerFiles.status_code === 200) {
            this.mediaFiles$.next(res.mediaServerFiles.data.Files);
            this.totalmediafiles = res.mediaServerFiles.data.total;
          }
        } else if (res.error) {
          const dispErr = res.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });
  }

  initiateCampaignSetupSub() {
    this.campaignsetup
      .get('camapaignCategory')
      .valueChanges.subscribe((value) => {
        if (value === 'Promotional') {
          this.campaignsetup.get('templateCategory').reset();
          this.templatesList$.next([]);
          this.tempCategory = [{ id: 1, name: 'Marketing' }];
        } else if (value === 'Transactional') {
          this.campaignsetup.get('templateCategory').reset();
          this.templatesList$.next([]);
          this.tempCategory = [
            { id: 1, name: 'Utility' },
            { id: 2, name: 'Authentication', disabled: true },
          ];
        }
      });

    this.campaignsetup.get('contacttype').valueChanges.subscribe((value) => {
      this.setDailCode(value);
    });

    this.campaignsetup.get('countrycode').setValue(this.selectedDialCode);

    this.campaignUploadCSV
      .get('scheduledcountry')
      .valueChanges.subscribe((value) => {
        if (value) {
          const country = ct.getCountry(value.toUpperCase());
          this.timeZones = country.timezones;
        }
      });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllFilesSelected() {
    const numSelected = this.fileselection.selected.length;
    const numRows = this.mediaFiles$.getValue().length;
    return numSelected === numRows;
  }

  toggleAllFileRows() {
    if (this.isAllFilesSelected()) {
      this.fileselection.clear();
      return;
    }

    this.fileselection.select(...this.mediaFiles$.getValue());
  }

  filecheckboxLabel(row?: MediaFile): string {
    if (!row) {
      return `${this.isAllFilesSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.fileselection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }

  selectHandler(row: MediaFile) {
    if (!this.fileselection.isSelected(row)) {
      this.fileselection.clear();
    }
    this.fileselection.toggle(row);
  }

  get csFC() {
    return this.campaignsetup.controls;
  }

  removeKeyword(keyword: string) {
    this.campaignTags = JSON.parse(JSON.stringify(this.campaignTags));
    const index = this.campaignTags.indexOf(keyword);
    if (index >= 0) {
      this.campaignTags.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    if (this.campaignTags && this.campaignTags.length < 5) {
      this.campaignTags = JSON.parse(JSON.stringify(this.campaignTags));
      const value = (event.value || '').trim();

      // Add our keyword
      if (value) {
        this.campaignTags.push(value);
      }

      // Clear the input value
      event.chipInput?.clear();
    } else if (event.value) {
      this.alertMsg.alertWarning('Warning', 'Maximum 5 tags are allowed');
    }
  }

  edit(keyword: string, event: MatChipEditedEvent) {
    const value = event.value.trim();
    this.campaignTags = JSON.parse(JSON.stringify(this.campaignTags));
    // Remove fruit if it no longer has a name
    if (!value) {
      this.removeKeyword(keyword);
      return;
    }

    // Edit existing fruit
    const index = this.campaignTags.indexOf(keyword);
    if (index >= 0) {
      this.campaignTags[index] = value;
    }
  }

  detectChangeTemp(event: any) {
    if (event && event.name === 'Marketing') {
      this.getTemplates('marketing');
    } else if (event && event.name === 'Utility') {
      this.getTemplates('utility');
    }
  }

  getTemplates(action: string) {
    this.temp_payload_var = JSON.parse(JSON.stringify(this.temp_payload_var));
    this.temp_payload_var.category = action;
    this.temp_payload_var.status = 'approved';
    this.store.dispatch(
      templateactions.fetchtemplateslist({ payload: this.temp_payload_var })
    );
  }

  cancelCampaignSetup() {
    this.stepper.reset();
    this.campaignsetup.reset();
    this.isSaveToDraft = false;
    this.selectedTemplate = null;
    this.store.dispatch(
      campaignactions.campaignPreviewData({
        campaignPreview: this.selectedTemplate,
      })
    );
    this.gotoListing();
  }

  onFileSelect(data: any) {
    this.file_urls.header = data[0];
    if (this.stepper.selectedIndex === 0) {
      this.insertMedia(this.file_urls.header);
    }
    this.modalService.dismissAll();
  }

  insertMedia(data: any) {
    if (
      this.selectedTemplate.content.header &&
      this.selectedTemplate.content.header.header_type === 'media'
    ) {
      this.selectedTemplate = JSON.parse(JSON.stringify(this.selectedTemplate));
      this.selectedTemplate.content.header.data[0].url = data.url;
      this.campaignsetup.get('headerMedia').setValue(data.url);
      this.store.dispatch(
        campaignactions.campaignPreviewData({
          campaignPreview: this.selectedTemplate,
        })
      );
    }
  }

  downloadSampleFile(action: string) {
    const url = '/assets/files/multi_contact_bulk_upload.csv';
    const filename = 'multi_contact_bulk_upload_sample.csv';
    this.shareservice.downloadSampleFile(url, action, filename);
  }

  sendPreProcessing(data: any) {
    this.campaignUploadCSV.get('mediaURL').setValue(data.url);
    if (!this.selectedCampaignData) {
      const sendPayload = {
        account_id: this.account_dtls.account_id,
        channel: this.account_dtls.channel,
        waba_number: this.account_dtls.waba_no,
        bsp_id: this.account_dtls.bsp_id,
        campaign_name: this.campaignsetup.get('campaignName').value,
        created_by: this.account_dtls.createdby,
        tags: this.campaignTags,
        template_category: this.campaignsetup
          .get('templateCategory')
          .value.toLowerCase(),
        campaign_category: this.campaignsetup
          .get('camapaignCategory')
          .value.toLowerCase(),
        template_data: this.selectedTemplate,
        is_drafted: this.isSaveToDraft,
        is_bulk_campaign: true,
        country_code: this.campaignsetup.get('countrycode').value
          ? '+' + this.campaignsetup.get('countrycode').value
          : null,
        bulk_upload_url: data.url,
      };
      this.store.dispatch(
        campaignactions.campaignPreprocessingData({
          datapayload: sendPayload,
        })
      );
      this.store.dispatch(commonactions.clearuploadFile());
    } else {
      const sendPayload = {
        id: this.selectedCampaignData.id,
        account_id: this.account_dtls.account_id,
        channel: this.account_dtls.channel,
        waba_number: this.account_dtls.waba_no,
        bsp_id: this.account_dtls.bsp_id,
        campaign_name: this.campaignsetup.get('campaignName').value,
        created_by: this.account_dtls.createdby,
        tags: this.campaignTags,
        template_category: this.campaignsetup
          .get('templateCategory')
          .value.toLowerCase(),
        campaign_category: this.campaignsetup
          .get('camapaignCategory')
          .value.toLowerCase(),
        template_data: this.selectedTemplate,
        is_drafted: this.isSaveToDraft,
        is_bulk_campaign: true,
        country_code: this.campaignsetup.get('countrycode').value
          ? '+' + this.campaignsetup.get('countrycode').value
          : null,
        bulk_upload_url: data.url,
      };

      this.store.dispatch(
        campaignactions.updateCampaignAction.updateCampaign({
          datapayload: sendPayload,
        })
      );
    }
  }

  onSelect(event: any) {
    const file = event.target.files[0];

    if (!file) return;

    const fileType = file.type;
    const fileSizeMB = file.size / 1024 / 1024;

    const fileTypes: any = {
      'text/csv': {
        maxSizeMB: 2,
        errorMessage: "You can't upload CSV files which are greater than 2MB",
      },
      'video/mp4': {
        maxSizeMB: 16,
        errorMessage:
          "You can't upload video files which are greater than 16MB",
      },
      'video/3gpp': {
        maxSizeMB: 16,
        errorMessage:
          "You can't upload video files which are greater than 16MB",
      },
      'application/pdf': {
        maxSizeMB: 100,
        errorMessage: "You can't upload documents which are greater than 100MB",
      },
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        {
          maxSizeMB: 100,
          errorMessage:
            "You can't upload documents which are greater than 100MB",
        },
      'image/jpeg': {
        maxSizeMB: 5,
        errorMessage: "You can't upload images which are greater than 5MB",
      },
      'image/png': {
        maxSizeMB: 5,
        errorMessage: "You can't upload images which are greater than 5MB",
      },
    };

    const allowedTypes = Object.keys(fileTypes);

    if (!allowedTypes.includes(fileType)) {
      this.alertMsg.alertWarning('Warning!', 'Unsupported file type');
      return;
    }

    const fileConfig = fileTypes[fileType];

    if (fileSizeMB > fileConfig.maxSizeMB) {
      this.alertMsg.alertWarning('Warning!', fileConfig.errorMessage);
      return;
    }

    this.file = file;

    this.store.dispatch(
      commonactions.initiateuploadFile({
        file: this.file,
        accountid: this.account_dtls.account_id,
        mobileno: this.account_dtls.waba_no,
        channel: this.account_dtls.channel,
        createdby: this.account_dtls.createdby,
        is_active: false,
      })
    );
  }

  saveToDraft() {
    this.isSaveToDraft = true;
    const sendPayload: any = {
      account_id: this.account_dtls.account_id,
      channel: this.account_dtls.channel,
      waba_number: this.account_dtls.waba_no,
      bsp_id: this.account_dtls.bsp_id,
      created_by: this.account_dtls.createdby,
      campaign_name: this.campaignsetup.get('campaignName').value,
      template_category: this.campaignsetup
        .get('templateCategory')
        .value.toLowerCase(),
      campaign_category: this.campaignsetup
        .get('camapaignCategory')
        .value.toLowerCase(),
      tags: this.campaignTags,
      template_data: this.selectedTemplate,
      is_drafted: this.isSaveToDraft,
      is_bulk_campaign:
        this.campaignsetup.get('contacttype').value.toLowerCase() ===
        'multiplecontacts',
      country_code: this.campaignsetup.get('countrycode').value
        ? '+' + this.campaignsetup.get('countrycode').value
        : null,
      bulk_upload_url: this.campaignUploadCSV.get('mediaURL').value,
    };

    if (this.campaignUploadCSV.get('nonConsent').value === true) {
      sendPayload['is_send_to_non_consent_recipients'] =
        this.campaignUploadCSV.get('nonConsent').value;
    }

    if (this.campaignUploadCSV.get('scheduleCampaign').value === true) {
      sendPayload['scheduled_country'] =
        this.campaignUploadCSV.get('scheduledcountry').value;
      sendPayload['scheduled_timezone'] =
        this.campaignUploadCSV.get('scheduledtimezone').value;
      sendPayload['scheduled_at'] = this.campaignUploadCSV
        .get('scheduleDateTime')
        .value.toUTCString();
    }

    if (this.selectedCampaignData) {
      sendPayload['id'] = this.selectedCampaignData.id;
      this.store.dispatch(
        campaignactions.updateCampaignAction.updateCampaign({
          datapayload: sendPayload,
        })
      );
    } else {
      this.store.dispatch(
        campaignactions.saveCampaignAction.saveCampaign({
          datapayload: sendPayload,
        })
      );
    }
  }

  scheduleCampaign() {
    let sendPayload: any;
    this.checkVariables();
    if (this.selectedCampaignData) {
      sendPayload = {
        id: this.selectedCampaignData.id,
        bulk_upload_url: this.campaignUploadCSV.get('mediaURL').value,
        is_send_to_non_consent_recipients:
          this.campaignUploadCSV.get('nonConsent').value,
      };
      if (this.campaignUploadCSV.get('scheduleCampaign').value === true) {
        sendPayload['scheduled_country'] =
          this.campaignUploadCSV.get('scheduledcountry').value;
        sendPayload['scheduled_timezone'] =
          this.campaignUploadCSV.get('scheduledtimezone').value;
        sendPayload['scheduled_at'] =
          this.campaignUploadCSV.get('scheduleDateTime').value;
      }
      this.loader.show();
      this.store.dispatch(
        campaignactions.createCampaign({ datapayload: sendPayload })
      );
    } else {
      sendPayload = {
        account_id: this.account_dtls.account_id,
        channel: this.account_dtls.channel,
        waba_number: this.account_dtls.waba_no,
        bsp_id: this.account_dtls.bsp_id,
        campaign_name: this.campaignsetup.get('campaignName').value,
        created_by: this.account_dtls.createdby,
        template_category: this.campaignsetup
          .get('templateCategory')
          .value.toLowerCase(),
        campaign_category: this.campaignsetup
          .get('camapaignCategory')
          .value.toLowerCase(),
        tags: this.campaignTags,
        template_data: this.selectedTemplate,
        is_drafted: this.isSaveToDraft,
        is_bulk_campaign:
          this.campaignsetup.get('contacttype').value.toLowerCase() ===
          'multiplecontacts',
        country_code: this.campaignsetup.get('countrycode').value
          ? '+' + this.campaignsetup.get('countrycode').value
          : null,
        bulk_upload_url: this.campaignUploadCSV.get('mediaURL').value,
        is_send_to_non_consent_recipients:
          this.campaignUploadCSV.get('nonConsent').value,
      };

      if (this.campaignUploadCSV.get('scheduleCampaign').value === true) {
        sendPayload['scheduled_country'] =
          this.campaignUploadCSV.get('scheduledcountry').value;
        sendPayload['scheduled_timezone'] =
          this.campaignUploadCSV.get('scheduledtimezone').value;
        sendPayload['scheduled_at'] = this.campaignUploadCSV
          .get('scheduleDateTime')
          .value.toUTCString();
      }

      this.loader.show();
      this.store.dispatch(
        campaignactions.createCampaign({ datapayload: sendPayload })
      );
    }
  }

  checkVariables() {
    if (
      this.selectedTemplate.content.header &&
      this.selectedTemplate.content.header.header_type === 'text' &&
      this.selectedTemplate.content.header.variables &&
      this.selectedTemplate.content.header.variables.length > 0
    ) {
      const header_var: any = [];
      this.headervariablesarray.value.forEach((t: any) => {
        const variableObj: any = {};
        variableObj[t.variable_val] = t.variable_header;
        header_var.push(variableObj);
      });
      this.selectedTemplate = JSON.parse(JSON.stringify(this.selectedTemplate));
      this.selectedTemplate.content.header.variables = header_var;
    }

    if (
      this.selectedTemplate.content.body.variables &&
      this.selectedTemplate.content.body.variables.length > 0
    ) {
      const body_var: any = [];
      this.bodyvariablesarray.value.forEach((t: any) => {
        const bodyvarObj: any = {};
        bodyvarObj[t.variable_val] = t.variable_body;
        body_var.push(bodyvarObj);
      });
      this.selectedTemplate = JSON.parse(JSON.stringify(this.selectedTemplate));
      this.selectedTemplate.content.body.variables = body_var;
    }

    if (
      this.selectedTemplate.content.button_details &&
      this.selectedTemplate.content.button_details.type === 'call_to_action' &&
      this.selectedTemplate.content.button_details.buttons.find(
        (t: any) => t.url_type === 'dynamic'
      )
    ) {
      this.selectedTemplate = JSON.parse(JSON.stringify(this.selectedTemplate));
      const dynamic_btn_index =
        this.selectedTemplate.content.button_details.buttons.findIndex(
          (t: any) => t.url_type === 'dynamic'
        );

      const dynamic_Obj: any = {};
      if (this.dynamicvariablesarray.value[0]) {
        dynamic_Obj[this.dynamicvariablesarray.value[0].variable_val] =
          this.dynamicvariablesarray.value[0].variable_url;

        this.selectedTemplate.content.button_details.buttons[dynamic_btn_index][
          'variables'
        ] = dynamic_Obj;
      }
    }
  }

  bodyVariablesChanged() {
    this.checkVariables();
    this.store.dispatch(
      campaignactions.campaignPreviewData({
        campaignPreview: this.selectedTemplate,
      })
    );
  }

  onCampaignTemplate() {
    let sendPayload: any;
    this.checkVariables();
    if (
      this.campaignsetup.get('contacttype').value.toLowerCase() ===
      'singlecontact'
    ) {
      sendPayload = {
        account_id: this.account_dtls.account_id,
        channel: this.account_dtls.channel,
        waba_number: this.account_dtls.waba_no,
        bsp_id: this.account_dtls.bsp_id,
        campaign_name: this.campaignsetup.get('campaignName').value,
        created_by: this.account_dtls.createdby,
        template_category: this.campaignsetup
          .get('templateCategory')
          .value.toLowerCase(),
        campaign_category: this.campaignsetup
          .get('camapaignCategory')
          .value.toLowerCase(),
        tags: this.campaignTags,
        template_data: this.selectedTemplate,
        is_drafted: this.isSaveToDraft,
        is_bulk_campaign:
          this.campaignsetup.get('contacttype').value.toLowerCase() !==
          'singlecontact',
        country_code:
          '+' + this.campaignsetup.get('countrycode').value.dialCode,
        mobile_number: this.campaignsetup.get('contactno').value.toString(),
      };
      this.loader.show();
      this.store.dispatch(
        campaignactions.createCampaign({ datapayload: sendPayload })
      );
    } else {
      if (this.selectedCampaignData) {
        sendPayload = {
          id: this.selectedCampaignData.id,
          bulk_upload_url: this.campaignUploadCSV.get('mediaURL').value,
          is_send_to_non_consent_recipients:
            this.campaignUploadCSV.get('nonConsent').value,
        };
        this.loader.show();
        this.store.dispatch(
          campaignactions.createCampaign({ datapayload: sendPayload })
        );
      } else {
        sendPayload = {
          account_id: this.account_dtls.account_id,
          channel: this.account_dtls.channel,
          waba_number: this.account_dtls.waba_no,
          bsp_id: this.account_dtls.bsp_id,
          campaign_name: this.campaignsetup.get('campaignName').value,
          created_by: this.account_dtls.createdby,
          template_category: this.campaignsetup
            .get('templateCategory')
            .value.toLowerCase(),
          campaign_category: this.campaignsetup
            .get('camapaignCategory')
            .value.toLowerCase(),
          tags: this.campaignTags,
          template_data: this.selectedTemplate,
          is_drafted: this.isSaveToDraft,
          is_bulk_campaign:
            this.campaignsetup.get('contacttype').value.toLowerCase() ===
            'multiplecontacts',
          country_code: this.campaignsetup.get('countrycode').value
            ? '+' + this.campaignsetup.get('countrycode').value
            : null,
          bulk_upload_url: this.campaignUploadCSV.get('mediaURL').value,
          is_send_to_non_consent_recipients:
            this.campaignUploadCSV.get('nonConsent').value,
        };
        this.loader.show();
        this.store.dispatch(
          campaignactions.createCampaign({ datapayload: sendPayload })
        );
      }
    }
  }

  compareFn(item: any, selected: any) {
    return item.dialCode === selected.dialCode;
  }

  compareFnCampaignCategory(item: any, selected: any) {
    return item.name === selected[0];
  }

  compareFnTemplateCategory(item: any, selected: any) {
    return item.name === selected[0];
  }

  compareFnTemplateName(item: any, selected: any) {
    return item.id === selected;
  }

  // size: 'lg',
  openModal(content: any) {
    this.modalService.open(content, {
      centered: true,
      scrollable: true,
      size: 'xl',
      backdrop: 'static',
    });
  }

  //Method to fetch the media files
  getMediaFiles(action: string) {
    this.mediadrive_payload_var = JSON.parse(
      JSON.stringify(this.mediadrive_payload_var)
    );
    this.mediadrive_payload_var.filetype = action;
    this.store.dispatch(
      commonactions.fetchMediaServerData({
        payload: this.mediadrive_payload_var,
      })
    );
  }

  // This Method clears the input variables and their validations in header, body and cta
  clearTemplateVariablesControls() {
    this.campaignsetup.get('headerMedia').setValue(null);
    this.campaignsetup.controls['headerMedia'].clearValidators();
    this.campaignsetup.controls['headerMedia'].updateValueAndValidity();
    this.headervariablesarray.clear();
    this.bodyvariablesarray.clear();
    this.dynamicvariablesarray.clear();
    this.urlText = false;
  }

  // This Method checks the selected template and adds the input variables and validations.
  onChooseTemplate(event: any) {
    this.clearTemplateVariablesControls();
    let sel_template: any = null;
    sel_template = JSON.parse(JSON.stringify(event));
    this.selectedTemplate = sel_template;
    if (typeof sel_template.content === 'string') {
      sel_template.content = JSON.parse(sel_template.content);
    }

    // For setting the variable values back while saving the campaing to DRAFT
    let isVar: any;
    if (this.selectedCampaignData) {
      let test = { ...this.selectedCampaignData };
      test = {
        ...JSON.parse(JSON.stringify(test)),
      };
      const temp = JSON.parse(test.template_payload);
      isVar =
        test && test.status == 'draft' && temp && temp.id == sel_template.id;
    }

    if (
      sel_template.content.header &&
      sel_template.content.header.header_type === 'media'
    ) {
      if (sel_template.content.header.data) {
        this.file_urls.header = {
          url: sel_template.content.header.data[0].url,
          file_name: 'image',
        };

        this.campaignsetup
          .get('headerMedia')
          .setValue(sel_template.content.header.data[0].url);
        this.campaignsetup.controls['headerMedia'].setValidators([
          Validators.required,
        ]);
        this.campaignsetup.controls['headerMedia'].updateValueAndValidity();
      } else {
        this.campaignsetup.controls['headerMedia'].setValidators([
          Validators.required,
        ]);
        this.campaignsetup.controls['headerMedia'].updateValueAndValidity();
      }
    }

    if (
      sel_template.content.header &&
      sel_template.content.header.header_type === 'text' &&
      sel_template.content.header.variables &&
      sel_template.content.header.variables.length > 0
    ) {
      if (sel_template.content.header.variables[0]['1'] === 'var1') {
        sel_template.content.header.examples.forEach(
          (t: any, index: number) => {
            const initialForm: FormGroup = this.fb.group({
              variable_val: [index + 1],
              variable_header: [isVar ? t[index + 1] : '', Validators.required],
              // variable_header: [t[index + 1], Validators.required],
            });
            this.headervariablesarray.push(initialForm);
          }
        );
      } else {
        sel_template.content.header.variables.forEach(
          (t: any, index: number) => {
            const initialForm: FormGroup = this.fb.group({
              variable_val: [index + 1],
              variable_header: [isVar ? t[index + 1] : '', Validators.required],
            });
            this.headervariablesarray.push(initialForm);
          }
        );
      }
    }

    if (
      sel_template.content.body.variables &&
      sel_template.content.body.variables.length > 0
    ) {
      if (sel_template.content.body.variables[0]['1'] === 'var1') {
        sel_template.content.body.examples.forEach((t: any, index: number) => {
          const initialForm: FormGroup = this.fb.group({
            variable_val: [index + 1],
            variable_body: [isVar ? t[index + 1] : '', Validators.required],
            // variable_body: [t[index + 1], Validators.required],
          });
          this.bodyvariablesarray.push(initialForm);
        });
      } else {
        sel_template.content.body.variables.forEach((t: any, index: number) => {
          const initialForm: FormGroup = this.fb.group({
            variable_val: [index + 1],
            variable_body: [isVar ? t[index + 1] : '', Validators.required],
            // variable_body: [t[index + 1], Validators.required],
          });
          this.bodyvariablesarray.push(initialForm);
        });
      }
    }

    if (
      sel_template.content.button_details &&
      sel_template.content.button_details.type === 'call_to_action'
    ) {
      const dynamic_btn = sel_template.content.button_details.buttons.find(
        (t: any) => t.url_type === 'dynamic'
      );

      if (dynamic_btn) {
        const initialForm: FormGroup = this.fb.group({
          variable_val: ['1'],
          variable_url: [
            isVar && dynamic_btn?.variables ? dynamic_btn.variables['1'] : '',
            Validators.required,
          ],
          // variable_url: [
          //   dynamic_btn?.variables ? dynamic_btn.variables['1'] : null,
          //   Validators.required,
          // ],
        });
        this.dynamicvariablesarray.push(initialForm);
      }
    }

    this.bodyVariablesChanged();
  }

  //Method checks and validates weather the entered string in the media upload input is url.
  checkInsertedUrl(event: any) {
    const url = event.target.value;
    const pattern = new RegExp(
      /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g
    );

    // const pattern = new RegExp(
    //   /^(https?:\/\/)?([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}(:\d+)?(\/.*)?$/g
    // );
    const result = pattern.test(url);

    if (result) {
      this.store.dispatch(
        commonactions.initiateuploadUrl({
          url: url,
          accountid: this.account_dtls.account_id,
          mobileno: this.account_dtls.waba_no,
          channel: this.account_dtls.channel,
          createdby: this.account_dtls.createdby,
        })
      );
    } else {
      // For sending the dynamic url to header media variables
      if (url) {
        // const varLinkPattern = /{{(.*?)}}/g;
        // if (event.target.value.match(varLinkPattern)?.length > 0) {
        //   const Urltext = event.target.value.replaceAll(/{{|}}/gi, '');
        //   this.selectedTemplate = JSON.parse(JSON.stringify(this.selectedTemplate));
        //   this.selectedTemplate.content.header['variables'] = [{1: Urltext}];
        // } else {
        //   // return;
        // }
        this.urlText = true;
        const Urltext = event.target.value;
        this.selectedTemplate = JSON.parse(
          JSON.stringify(this.selectedTemplate)
        );
        this.selectedTemplate.content.header['variables'] = [{ 1: Urltext }];
        this.campaignsetup.get('headerMedia').setValue(url);
      } else {
        this.urlText = false;
        this.clearMediaData();
      }
    }
  }

  createTemplateNavigation() {
    this.router.navigate([
      this.shareservice.getRoutingUrl(),
      this.userDetails.profile.id,
      'templates',
      'create',
    ]);
  }

  gotoListing() {
    this.router.navigate([
      this.shareservice.getRoutingUrl(),
      this.userDetails.profile.id,
      'campaign-manager',
    ]);
    this.store.dispatch(campaignactions.resetCampaignPreviewData());
  }

  onPageEvent(event: any) {
    this.page = event;
    this.mediadrive_payload_var = JSON.parse(
      JSON.stringify(this.mediadrive_payload_var)
    );
    this.mediadrive_payload_var.page = this.page;
    this.store.dispatch(
      commonactions.fetchMediaServerData({
        payload: this.mediadrive_payload_var,
      })
    );
  }

  searchtemplate = (term: string) => {
    const searchterm = term.trim();
    this.temp_payload_var = JSON.parse(JSON.stringify(this.temp_payload_var));
    this.temp_payload_var.template_name = searchterm;
    this.getTemplates('marketing');
  };

  onScrollToEnd() {
    this.temp_payload_var = JSON.parse(JSON.stringify(this.temp_payload_var));
    this.temp_payload_var.page = this.temp_payload_var.page + 1;
    if (this.totalTemplateList > this.templatesList$.getValue().length) {
      this.getTemplates(this.temp_payload_var.category);
    }
  }

  checkData() {
    if (this.selectedCampaignData) {
      if (this.selectedCampaignData.bulk_upload_url) {
        // this.isAnalysing = true;
        setTimeout(() => {
          this.store.dispatch(
            campaignactions.FetchCampaignValidation({
              id: this.selectedCampaignData.id,
            })
          );
        }, 3000);
      }
    }
  }

  onRemovingCsvFile() {
    this.file = null;
    this.campaignUploadCSV.get('mediaURL').setValue(null);
    this.file_urls.bulkupload = null;
    this.isAnalysing = false;
    this.preprocessing_data = null;

    const sendPayload: any = {
      account_id: this.account_dtls.account_id,
      channel: this.account_dtls.channel,
      waba_number: this.account_dtls.waba_no,
      bsp_id: this.account_dtls.bsp_id,
      created_by: this.account_dtls.createdby,
      campaign_name: this.campaignsetup.get('campaignName').value,
      template_category: this.campaignsetup
        .get('templateCategory')
        .value.toLowerCase(),
      campaign_category: this.campaignsetup
        .get('camapaignCategory')
        .value.toLowerCase(),
      tags: this.campaignTags,
      template_data: this.selectedTemplate,
      is_drafted: this.isSaveToDraft,
      is_bulk_campaign:
        this.campaignsetup.get('contacttype').value.toLowerCase() ===
        'multiplecontacts',
      country_code: this.campaignsetup.get('countrycode').value
        ? '+' + this.campaignsetup.get('countrycode').value
        : null,
      bulk_upload_url: null,
      is_bulk_file_deleted: true,
    };

    if (this.selectedCampaignData) {
      sendPayload['id'] = this.selectedCampaignData.id;

      this.store.dispatch(
        campaignactions.updateCampaignAction.updateCampaign({
          datapayload: sendPayload,
        })
      );
    } else {
      this.store.dispatch(
        campaignactions.updateCampaignAction.updateCampaign({
          datapayload: sendPayload,
        })
      );
    }
  }

  checkCampaignName() {
    if (this.campaignsetup.get('campaignName').value) {
      const campaignName = this.campaignsetup.get('campaignName').value.trim();
      const campaignVariables: CampaignVariables = {
        accountid: this.account_dtls.account_id,
        channel: this.account_dtls.channel,
        bspid: this.account_dtls.bsp_id,
        wabano: this.account_dtls.waba_no,
        campaign_name: campaignName,
        template_category: null,
        page: null,
        size: null,
        category: null,
        type: null,
        tag: null,
        profile: null,
        status: null,
        startdate: null,
        enddate: null,
      };
      this.campaignService.fetchcampaigns(campaignVariables).subscribe({
        next: (response) => {
          if (response.status_code === 200) {
            const cNameRes = response.data.find(
              (i: any) => i.name === campaignName
            );

            if (
              this.router.url.includes('edit') &&
              cNameRes.name === this.editCampaignPrvsName
            ) {
              this.campaignNameExists = false;
            } else {
              this.campaignNameExists = response.data.some(
                (i: any) => i.name === campaignName
              );
            }
          }
        },
        error: (error) => {
          this.campaignNameExists = false;
        },
      });
    } else {
      this.campaignNameExists = false;
      return;
    }
  }

  // Clear Media Selected Media file and assign input validations to the media URL input
  clearMediaData() {
    this.campaignsetup.get('headerMedia').setValue(null);
    this.campaignsetup.get('headerMedia').setValidators([Validators.required]);
    this.campaignsetup.get('headerMedia').updateValueAndValidity();
  }

  refreshValidations() {
    this.isAnalysing = true;
    this.showRefreshBtn = false;
    this.store.dispatch(
      campaignactions.FetchCampaignValidation({
        id: this.selectedCampaignData.id,
      })
    );
  }

  ngOnDestroy() {
    this.channelSub.unsubscribe();
    this.store.dispatch(campaignactions.resetCampaignData());
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

export const DateTimeValidator = (fc: FormControl) => {
  const date = new Date(fc.value);
  const isValid = !isNaN(date.valueOf());
  return isValid
    ? null
    : {
        isValid: {
          valid: false,
        },
      };
};
