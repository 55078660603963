<div class="content container-fluid">
  <div class="card p-3">
    <div class="top_header_options">
      <div class="d-flex align-items-center gap-2">
        <button class="btn btn-sm btn-icon btn-light" (click)="gotoListing()">
          <em class="bi bi-chevron-left"></em>
        </button>
        <h3 class="main_title">
          {{ exportTemplate ? "Export" : "Create A Report" }}
        </h3>
      </div>
    </div>
  </div>

  <div class="card card-vh-100 mt-2">
    <div class="card-body">
      <form [formGroup]="createReportForm">
        <!-- Report Name -->
        <div class="form-group">
          <div class="row">
            <!-- Report Name -->
            <div class="col">
              <label class="input-label" for="reportname"
                >Report Name
                <span class="text-danger" matTooltip="Report Name is mandatory"
                  >*</span
                >
                <span class="text-muted float-end" *ngIf="reportCharCount > 0">
                  {{ reportCharCount }}/{{ maxCharCount }}
                </span>
              </label>

              <input
                class="form-control"
                type="text"
                id="reportname"
                [maxlength]="maxCharCount"
                placeholder="Report Name"
                formControlName="reportName"
              />
              <!-- Report Name Error -->
              <div class="invalid-feedback">
                <div
                  *ngIf="crFC['reportName'].touched || crFC['reportName'].dirty"
                >
                  <ng-container
                    *ngIf="
                      crFC['reportName'].value === '' ||
                        crFC['reportName'].value === null;
                      else fcInvalid
                    "
                    >Report name can not be empty.</ng-container
                  >
                  <ng-template #fcInvalid>
                    <span *ngIf="crFC['reportName'].errors"
                      >Please enter a valid report name.</span
                    >
                  </ng-template>
                </div>
              </div>
              <!-- END Report Name Error -->
            </div>
            <!-- END Report Name -->

            <!-- Date Range Selection -->
            <div class="col">
              <label class="input-label" for="daterange"
                >Select Date Range
                <span class="text-danger" matTooltip="Date Range is mandatory"
                  >*</span
                >
              </label>
              <app-datepicker></app-datepicker>
            </div>
            <!-- END Date Range Selection -->
          </div>
        </div>
        <!-- ENd Report Name -->

        <!-- Date Range, Report Type, Categories -->
        <div class="form-group" *ngIf="!exportTemplate">
          <div class="row">
            <!-- Report Type Selection -->
            <div class="col">
              <label class="input-label" for="reporttype"
                >Report Type
                <span class="text-danger" matTooltip="Report Type is mandatory"
                  >*</span
                >
              </label>

              <ng-select
                [items]="reportTypeList"
                [placeholder]="'Select Report Type'"
                [multiple]="false"
                [searchable]="false"
                bindLabel="name"
                bindValue="value"
                [clearable]="false"
                formControlName="reportType"
                [closeOnSelect]="true"
                name="activerole"
                id="reporttype"
              >
              </ng-select>
            </div>
            <!-- END Report Type Selection -->

            <!-- Categories Selection -->
            <div
              class="col"
              *ngIf="createReportForm.get('reportType')?.value"
            >
              <label class="input-label" for="tempCategory"
                >Categories
                <span class="text-danger" matTooltip="Categories is mandatory"
                  >*</span
                >
              </label>

              <ng-select
                [items]="tempCategories"
                [placeholder]="'Select Category'"
                bindValue="value"
                bindLabel="name"
                [multiple]="false"
                [searchable]="false"
                [clearable]="false"
                formControlName="tempCategory"
                [closeOnSelect]="true"
                name="tempCategory"
                id="tempCategory"
                (change)="!exportTemplate ? onCategoryChange($event) : ''"
              >
                <!-- <ng-template
                  ng-multi-label-tmp
                  let-items="items"
                  let-clear="clear"
                >
                  <div
                    class="ng-value"
                    *ngFor="let item of items | slice : 0 : 2"
                  >
                    <span class="ng-value-label"> {{ item.name }}</span>
                    <span
                      class="ng-value-icon right"
                      (click)="clear(item)"
                      aria-hidden="true"
                      >×</span
                    >
                  </div>
                  <div
                    class="ng-value"
                    *ngIf="
                      createReportForm.get('tempCategory').value.length > 2
                    "
                  >
                    <span class="ng-value-label"
                      >{{
                        createReportForm.get("tempCategory").value.length - 2
                      }}
                      more...</span
                    >
                  </div>
                </ng-template>
                <ng-template
                  ng-optgroup-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index"
                >
                  <input
                    id="item-{{ index }}"
                    type="checkbox"
                    [ngModel]="item$.selected"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  Select All
                </ng-template>

                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index"
                >
                  <input
                    id="item-{{ index }}"
                    type="checkbox"
                    [ngModel]="item$.selected"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  {{ item.name }}
                </ng-template> -->
              </ng-select>
            </div>
            <!-- END Categories Selection -->
          </div>
        </div>
        <!-- END Date Range, Report Type, Categories-->

        <!-- Campaign List -->
        <div class="form-group" *ngIf="showCampaignList && (this.campaign_payload_var.startdate && this.campaign_payload_var.enddate)">
          <div class="row">
            <div class="col">
              <label class="input-label" for="tempCategory"
                >Campaign List
                <span
                  class="text-danger"
                  matTooltip="Campaign List is mandatory"
                  >*</span
                >
              </label>

              <div class="selected_chips_group">
                <mat-chip-listbox
                  aria-label="Enter campaigns"
                  *ngIf="!showAllCampaigns"
                >
                  <mat-chip-option
                    *ngFor="let item of selection.selected | slice : 0 : 10"
                    (removed)="selection.toggle(item); addCampaignListToForm()"
                  >
                    {{ item.name }}
                    <button
                      matChipRemove
                      [attr.aria-label]="'remove ' + item.name"
                    >
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-option>
                </mat-chip-listbox>

                <mat-chip-listbox
                  aria-label="Enter campaigns"
                  *ngIf="showAllCampaigns"
                >
                  <mat-chip-option
                    *ngFor="let item of selection.selected"
                    (removed)="selection.toggle(item); addCampaignListToForm()"
                  >
                    {{ item.name }}
                    <button
                      matChipRemove
                      [attr.aria-label]="'remove ' + item.name"
                    >
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-option>
                </mat-chip-listbox>

                <!-- More Campaign Selection -->
                <button
                  class="btn btn-outline-secondary btn-xs more_btn"
                  *ngIf="selection.selected.length > 10 && !showAllCampaigns"
                  (click)="showAllCampaigns = !showAllCampaigns"
                >
                  <em class="bi bi-plus-circle"></em>
                  {{ selection.selected.length - 10 }} More
                </button>

                <button
                  class="btn btn-outline-secondary btn-xs less_btn"
                  *ngIf="showAllCampaigns && selection.selected.length > 0"
                  (click)="showAllCampaigns = !showAllCampaigns"
                >
                  View Less
                </button>
                <!-- More Campaign Selection -->

                <!-- Add Campaign Selection -->
                <button
                  class="btn btn-outline-primary btn-xs"
                  [matMenuTriggerFor]="campaignListMenu"
                >
                  <em class="bi bi-plus-circle"></em> Add
                </button>
                <!-- END Add Campaign Selection -->

                <mat-menu #campaignListMenu="matMenu">
                  <section
                    class="list_selection_panel"
                    (click)="$event.stopPropagation()"
                  >
                    <span class="select_all_option">
                      <mat-checkbox
                        class="list-checkboxes"
                        (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="
                          selection.hasValue() && !isAllSelected()
                        "
                        [aria-label]="checkboxLabel()"
                      >
                        Select All
                      </mat-checkbox>
                    </span>
                    <span class="list-section">
                      <ul class="list-unstyled">
                        <li *ngFor="let item of campaignsList">
                          <mat-checkbox
                            class="list-checkboxes"
                            (click)="$event.stopPropagation()"
                            (change)="
                              $event ? selection.toggle(item) : null;
                              addCampaignListToForm()
                            "
                            [checked]="selection.isSelected(item)"
                            [aria-label]="checkboxLabel(item)"
                          >
                            {{ item.name }}
                          </mat-checkbox>
                        </li>
                      </ul>
                    </span>
                  </section>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
        <!-- END Campaign List -->

        <!-- Report Parameters -->
        <div class="form-group" *ngIf="(selection.selected.length > 0 && showCampaignList) && !exportTemplate">
          <div class="row">
            <div class="col">
              <label class="input-label" for="reportParameters"
                >Report Parameters
                <span
                  class="text-danger"
                  matTooltip="Report Parameters is mandatory"
                  >*</span
                >
              </label>

              <div
                class="parameters-wrapper d-flex align-items-center justify-content-between"
              >
                <div class="selected_chips_group">
                  <mat-chip-listbox
                    aria-label="Enter campaigns"
                    *ngIf="!showAllParameters"
                  >
                    <mat-chip-option
                      *ngFor="let item of parameters.selected | slice : 0 : 10"
                      (removed)="
                        !exportTemplate ? parameters.toggle(item) : null;
                        showWrapper = true;
                        showTable = false;
                        addParametersListToForm()
                      "
                    >
                      {{ item }}
                      <button
                        matChipRemove
                        [attr.aria-label]="'remove ' + item"
                      >
                        <mat-icon *ngIf="!exportTemplate">cancel</mat-icon>
                      </button>
                    </mat-chip-option>
                  </mat-chip-listbox>

                  <mat-chip-listbox
                    aria-label="Enter campaigns"
                    *ngIf="showAllParameters"
                  >
                    <mat-chip-option
                      *ngFor="let item of parameters.selected"
                      (removed)="
                        !exportTemplate ? parameters.toggle(item) : null;
                        showWrapper = true;
                        showTable = false;
                        addParametersListToForm()
                      "
                    >
                      {{ item }}
                      <button
                        matChipRemove
                        [attr.aria-label]="'remove ' + item"
                      >
                        <mat-icon *ngIf="!exportTemplate">cancel</mat-icon>
                      </button>
                    </mat-chip-option>
                  </mat-chip-listbox>

                  <!-- More Template Parameters -->
                  <button
                    class="btn btn-outline-secondary btn-xs more_btn"
                    *ngIf="
                      parameters.selected.length > 10 && !showAllParameters
                    "
                    (click)="showAllParameters = !showAllParameters"
                  >
                    <em class="bi bi-plus-circle"></em>
                    {{ parameters.selected.length - 10 }} More
                  </button>
                  <!-- END More Template Parameters -->

                  <!-- View Less Template Parameters -->
                  <button
                    class="btn btn-outline-secondary btn-xs less_btn"
                    *ngIf="showAllParameters && parameters.selected.length > 0"
                    (click)="showAllParameters = !showAllParameters"
                  >
                    View Less
                  </button>
                  <!-- END View Less Template Parameters -->

                  <!-- Add Template Parameters Selection -->
                  <button
                    class="btn btn-outline-primary btn-xs"
                    [matMenuTriggerFor]="parametersmenu"
                    *ngIf="!exportTemplate"
                  >
                    <em class="bi bi-plus-circle"></em> Add
                  </button>
                  <!-- END Add Template Parameters Selection -->

                  <button
                    class="btn btn-primary btn-sm sava_as_btn"
                    (click)="
                      openModal(saveReportTempModal, 'saveReportTemplate')
                    "
                    *ngIf="parameters.selected.length > 0 && !exportTemplate"
                    [disabled]="createReportForm.invalid || createReportForm.length === 0"
                  >
                    <em class="bi bi-save me-2"></em> Save As Template
                  </button>

                  <mat-menu #parametersmenu="matMenu">
                    <section class="list_selection_panel">
                      <span class="select_all_option">
                        <mat-checkbox
                          class="list-checkboxes"
                          (change)="
                            $event ? toggleParametersRows() : null;
                            showWrapper = true;
                            showTable = false
                          "
                          [checked]="
                            parameters.hasValue() && isParametersSelected()
                          "
                          [indeterminate]="
                            parameters.hasValue() && !isParametersSelected()
                          "
                          [aria-label]="checkboxParametersLabel()"
                        >
                          Select All
                        </mat-checkbox>
                      </span>
                      <span class="list-section">
                        <ul class="list-unstyled">
                          <li *ngFor="let item of templateparameterslist">
                            <mat-checkbox
                              class="list-checkboxes"
                              (click)="$event.stopPropagation()"
                              (change)="
                                $event ? parameters.toggle(item) : null;
                                showWrapper = true;
                                showTable = false;
                                addParametersListToForm()
                              "
                              [checked]="parameters.isSelected(item)"
                              [aria-label]="checkboxParametersLabel(item)"
                            >
                              {{ item }}
                            </mat-checkbox>
                          </li>
                        </ul>
                      </span>
                    </section>
                  </mat-menu>
                </div>

                <!-- <div class="parameters_list d-flex gap-2">
                  <button class="btn btn-outline-secondary btn-xs">
                    <em class="bi bi-plus-circle me-2"></em> Add
                  </button>
                  <button class="btn btn-primary btn-sm"
                      (click)="openModal(saveReportTempModal,'saveReportTemplate')"
                  >
                    <em class="bi bi-save me-2"></em> Save As Template
                  </button>
                </div> -->

                <!-- <div class="action_options d-flex gap-2">
                  <button class="btn btn-primary btn-sm">
                    Apply
                  </button>
                  <button class="btn btn-outline-secondary btn-sm">
                    Cancel
                  </button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- END Report Parameters -->

        <!-- Report Parameters Based Table -->
        <div
          class="table-wrapper my-2 d-none"
          *ngIf="parameters.selected.length > 0 && showTable"
        >
          <div class="table-container mb-0">
            <mat-table [dataSource]="reportsList">
              <ng-container
                [matColumnDef]="column"
                *ngFor="let column of parameters.selected"
              >
                <mat-header-cell *matHeaderCellDef>
                  {{ column }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element">{{
                  element[column]
                }}</mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="parameters.selected"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: parameters.selected"
              ></mat-row>

              <!-- Row shown when there is no matching data. -->
              <ng-container *matNoDataRow>
                <ngx-skeleton-loader
                  class="skeleton_loader"
                  *ngFor="let item of skeletonData"
                  [theme]="item"
                ></ngx-skeleton-loader>
              </ng-container>
            </mat-table>
          </div>
        </div>
        <!-- END Reports Parameters Based Table -->

        <!-- Snackbar Important Template Saving Alert -->
        <div
          class="SnackbarWrapper"
          *ngIf="(this.parameters.selected.length > 0 && showWrapper) && !exportTemplate"
        >
          <div class="SnackbarContainer">
            <div class="SnackbarContent card">
              <div
                class="info d-flex align-items-center justify-content-center gap-2"
              >
                <em class="bi bi-pencil"></em>
                <h5>
                  Click "Apply" To Use The Updated Configurations For The Report
                  View.
                </h5>
                <button
                  class="btn btn-primary btn-sm"
                  type="button"
                  (click)="showTable = true; showWrapper = false"
                >
                  Apply
                </button>
                <button class="btn btn-outline-secondary btn-sm"
                  (click)="showTable = false; showWrapper = false"
                >Cancel</button>
              </div>
            </div>
          </div>
        </div>
        <!-- END Snackbar Important Template Saving Alert -->

        <!-- Divider -->
        <mat-divider class="my-4" *ngIf="showTable"></mat-divider>
        <!-- END Divider -->

        <!-- Footer Buttons -->
        <div class="form_action_btns" *ngIf="(selection.selected.length > 0 && exportTemplate) || (showTable && createReportForm.valid)">
          <div class="options_left"></div>
          <div class="options_right">
            <button
              class="btn btn-outline-dark"
              type="button"
              (click)="gotoListing()"
            >
              Cancel
            </button>
            <button
              class="btn btn-gradient"
              type="button"
              matTooltip="Export report now"
              (click)="onCreateReport(createReportForm)"
              [disabled]="createReportForm.invalid"
            >
              Export
              <i class="bi-chevron-right ms-1"></i>
            </button>
          </div>
        </div>
        <!-- END Footer Buttons -->
      </form>
    </div>
  </div>
</div>

<!-- Save Report Template Modal -->
<ng-template #saveReportTempModal let-c="close" let-d="dismiss">
  <form [formGroup]="saveReportTempForm">
    <div class="modal-body text-center">
      <div
        class="avatar avatar-circle avatar-xl d-inline-flex align-items-center justify-content-center"
      >
        <em class="bi bi-file-text-fill fs-1 text-primary"></em>
      </div>
      <!-- Report Template Name -->
      <div class="form-group text-start">
        <label class="input-label" for="reporttempname"
          >Report Template Name
          <span
            class="text-danger"
            matTooltip="Report Template Name is mandatory"
            >*</span
          >
          <span class="text-muted float-end" *ngIf="rTempCharCount > 0">
            {{ rTempCharCount }}/{{ maxCharCount }}
          </span>
        </label>

        <input
          class="form-control"
          type="text"
          id="reporttempname"
          [maxlength]="maxCharCount"
          placeholder="Report Template Name"
          formControlName="reportTempName"
        />
        <!-- Report Name Error -->
        <div class="invalid-feedback">
          <div
            *ngIf="
              rtNameFC['reportTempName'].touched ||
              rtNameFC['reportTempName'].dirty
            "
          >
            <ng-container
              *ngIf="
                rtNameFC['reportTempName'].value === '' ||
                  rtNameFC['reportTempName'].value === null;
                else fcInvalid
              "
              >Report template name can not be empty.</ng-container
            >
            <ng-template #fcInvalid>
              <span *ngIf="rtNameFC['reportTempName'].errors"
                >Please enter a valid report template name.</span
              >
            </ng-template>
          </div>
        </div>
        <!-- END Report Name Error -->
      </div>
      <!-- ENd Report Template Name -->
    </div>
    <div class="modal-footer text-center">
      <button class="btn btn-light" (click)="d('Cross click')">Cancel</button>
      <button
        class="btn btn-primary"
        [disabled]="saveReportTempForm.invalid"
        (click)="saveAsTemplate()"
      >
        Save
      </button>
    </div>
  </form>
</ng-template>
<!-- END Save Report Template Modal -->
