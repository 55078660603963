import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { AnalyticsData } from 'src/app/appdata/analytics.model';
import { AuthUserData, ChannelCredentials } from 'src/app/appdata/auth.model';
import { CampaignDetails } from 'src/app/appdata/campaign.model';
import { skeleton_data } from 'src/app/appdata/skeleton.data';
import * as campaignactions from 'src/app/customer/campaign-manager/store/campaign.actions';
import { CampaignVariables } from 'src/app/services/campaign.service';
import { ReportsService } from 'src/app/services/reports.service';
import { SharedService } from 'src/app/services/shared.service';
import {
  selectProfilesDtlsData,
  selectSsoProfilesDtlsData,
} from 'src/app/store/common.selectors';
import { environment } from 'src/environments/environment';
import { selectCampaignActionData } from '../store/campaign.selectors';

@Component({
  selector: 'app-campaign-view',
  templateUrl: './campaignview.component.html',
  styleUrls: ['./campaignview.component.scss'],
})
export class CampaignViewComponent implements OnInit, OnDestroy {
  isCopied: boolean = false;
  showExportBtn: boolean = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  page = 1;
  pageSize = 10;
  viewCampaignDateDSubscription: Subscription;
  campaignAnalyticsData: AnalyticsData;
  totalanalyticsdata: number = 0;
  searchData: string = null;
  templateData: any;
  channelSub: Subscription;
  channel_credentails: ChannelCredentials;
  selectedCampaignStatus: string;
  campaignData: CampaignDetails;
  userDetails: AuthUserData | any;

  isRefreshing: boolean = false;
  skeletonData = skeleton_data;

  campaignStatus: string[] = [
    'submitted',
    'sent',
    'delivered',
    'read',
    'failed',
    'pending',
    'unread',
  ];

  campaign_payload_var: CampaignVariables = {
    accountid: null,
    channel: null,
    bspid: null,
    wabano: null,
    campaign_name: null,
    page: this.page,
    size: this.pageSize,
    type: null,
    tag: null,
    profile: null,
    status: null,
    startdate: null,
    enddate: null,
  };

  constructor(
    private readonly store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private shareservice: SharedService,
    private alertMsg: AlertService,
    private reportservice: ReportsService
  ) {}

  ngOnInit(): void {
    this.initUserDetails();
    this.initDatePicker();
    this.initCampaignData();
    this.initCampaignAnalytics();
  }

  initDatePicker(): void {
    this.shareservice.datePickerObject.next(null);
    this.channelSub = this.shareservice.selectedBA$.subscribe((value: any) => {
      if (value) {
        this.channel_credentails = value.channel_credentials;
        this.campaign_payload_var = {
          ...this.campaign_payload_var,
          ...{
            accountid: value.channel_credentials.account_id,
            bspid: value.channel_credentials.bsp_id,
            wabano: value.channel_credentials.waba_number,
            channel: value.channel_credentials.channel_name.toLowerCase(),
          },
        };
      }
    });
    this.activatedRoute.params.subscribe((params: Params) => {
      const campaign_id = params['id'];
      this.store.dispatch(
        campaignactions.CampaignViewDtls({
          id: campaign_id,
          accountid: this.campaign_payload_var.accountid,
        })
      );
    });
  }

  initUserDetails(): void {
    if (environment.sso) {
      this.store
        .select(selectSsoProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
          }
        });
    } else {
      this.store
        .select(selectProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
          }
        });
    }
  }

  initCampaignData(): void {
    this.viewCampaignDateDSubscription =
      this.shareservice.datePickerObject.subscribe((value) => {
        if (value) {
          if (value.from && value.to) {
            const fromDate: NgbDateStruct = value.from;
            const toDate: NgbDateStruct = value.to;
            this.campaign_payload_var = {
              ...this.campaign_payload_var,
              ...{
                startdate: `${fromDate.year}-${fromDate.month}-${fromDate.day}`,
                enddate: `${toDate.year}-${toDate.month}-${toDate.day}`,
              },
            };
            if (value.requestfrom === 'viewCampaignDetails') {
              this.fetchCampaignAnalytics();
            }
          }
          if (value.action === 'Date Cleared') {
            this.campaign_payload_var = {
              ...this.campaign_payload_var,
              ...{
                startdate: null,
                enddate: null,
              },
            };
            if (value.requestfrom === 'viewCampaignDetails') {
              this.fetchCampaignAnalytics();
            }
          }
        }
      });
  }

  initCampaignAnalytics(): void {
    this.store
      .select(selectCampaignActionData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (
          res.campaign_data?.individual_data &&
          res.campaign_data?.analytics_data
        ) {
          this.isRefreshing = true;

          this.campaignData = res.campaign_data.individual_data.data;
          this.templateData = JSON.parse(this.campaignData.template_payload);
          this.store.dispatch(
            campaignactions.CampaignViewTemplate({ payload: this.templateData })
          );
          this.campaignAnalyticsData = res.campaign_data.analytics_data;
          this.totalanalyticsdata = res.campaign_data.analytics_data.data.count;
        } else if (res.error && res.error.error.message === 'No data found') {
          this.alertMsg.alertDanger('Error!', res.error.error.message);
          this.campaignAnalyticsData = null;
          this.totalanalyticsdata = 0;
        }
      });
  }

  getTemplateName(data: any) {
    return JSON.parse(data).template_name;
  }

  getTempateData(data: any) {
    return JSON.parse(data);
  }

  applyFilter() {
    if (this.selectedCampaignStatus) {
      this.fetchCampaignAnalytics();
    }
  }

  copyData() {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 1000);
  }

  gotoListing() {
    this.router.navigate([
      this.shareservice.getRoutingUrl(),
      this.userDetails.profile.id,
      `campaign-manager`,
    ]);
  }

  clearCampaignAnalyticsFilter() {
    this.selectedCampaignStatus = null;
    this.fetchCampaignAnalytics();
  }

  fetchCampaignAnalytics(): void {
    const payload = {
      id: this.campaignData.id,
      accountid: this.campaign_payload_var.accountid,
      startdate: this.campaign_payload_var.startdate,
      enddate: this.campaign_payload_var.enddate,
      status: this.selectedCampaignStatus,
    };
    this.store.dispatch(
      campaignactions.FetchCampaignAnalyticsDtls({ data: payload })
    );
  }

  refreshCampaignAnalytics() {
    // this.isRefreshing = !this.isRefreshing;
    this.fetchCampaignAnalytics();
    this.isRefreshing = false;
  }

  createReport() {
    this.showExportBtn = false;

    const payload = {
      account_id: this.campaignData.account_id,
      bsp_id: this.campaignData.bsp_id,
      channel: this.campaignData.channel,
      waba_number: this.campaignData.waba_number,
      created_by: this.campaignData.created_by,
      report_name:
        this.campaignData.name +
        new Date().toISOString().slice(0, 16).replace('T', '-'), // —->campaign name with 4 digit id
      category: this.campaignData.category,
      report_type: 'campaign',
      properties: [
        'waba_number',
        'campaign_id',
        'template_id',
        'template_name',
        'campaign_name',
        'msisdn',
        'status',
        'message_id',
        'submitted_at',
        'failed_at',
        'sent_at',
        'delivered_at',
        'read_at',
        'error',
        'error_code',
      ],
      campaign_ids: [this.campaignData.id],
    };

    this.reportservice.createReport(payload).subscribe({
      next: (res: any) => {
        if (res.status_code === 200) {
          setTimeout(() => {
            this.showExportBtn = true;
          }, 30000);
          this.alertMsg.alertSuccess(
            'Success',
            res.message +
              ', you may check the export at reports section and download.'
          );
        } else {
          this.showExportBtn = true;
          this.alertMsg.alertDanger('Danger', res.message);
        }
      },
      error: (error: any) => {
        this.showExportBtn = true;
        if (error.message) {
          const dispErr = error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      },
    });
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }

  onPageEvent(event: any) {
    this.page = event;
    this.campaign_payload_var = {
      ...this.campaign_payload_var,
      ...{
        page: this.page,
      },
    };
    const payload = {
      id: this.campaignData.id,
      accountid: this.campaign_payload_var.accountid,
      page: this.page,
      size: this.pageSize,
    };
    this.store.dispatch(
      campaignactions.FetchCampaignAnalyticsDtls({ data: payload })
    );
  }

  ngOnDestroy(): void {
    this.viewCampaignDateDSubscription.unsubscribe();
    this.channelSub.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.shareservice.datePickerObject.next(null);
    this.store.dispatch(campaignactions.resetCampaignViewData());
    this.store.dispatch(campaignactions.ClearFetchCampaignError());
  }
}
