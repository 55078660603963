<div class="content container-fluid">
  <div class="card p-3">
    <div class="top_header_options">
      <div class="d-flex align-items-center gap-2">
        <button class="btn btn-sm btn-icon btn-light" (click)="gotoListing()">
          <em class="bi bi-chevron-left"></em>
        </button>
        <h3 class="main_title">
          {{ clonetemplate ? "Clone" : "Create Template" }}
        </h3>
      </div>
    </div>
  </div>

  <div class="card card-vh-100 mt-2">
    <div class="card-body">
      <form
        [formGroup]="createTemplateForm"
        (ngSubmit)="onSaveTemplate(createTemplateForm)"
      >
        <!-- Template Name -->
        <div class="form-group">
          <label class="input-label" for="reportname"
            >Template Name
            <span class="text-danger" matTooltip="Template Name is mandatory">*</span>
            <span class="text-muted float-end" *ngIf="tempCharCount > 0">
              {{ tempCharCount }}/{{ maxCharCount }}
            </span>
          </label>

          <div class="row">
            <div class="col">
              <input
                class="form-control"
                type="text"
                id="reportname"
                [maxlength]="maxCharCount"
                placeholder="Template Name"
                formControlName="templateName"
              />
              <!-- Template Name Error -->
              <div class="invalid-feedback">
                <div
                  *ngIf="
                    ctFC['templateName'].touched || ctFC['templateName'].dirty
                  "
                >
                  <ng-container
                    *ngIf="
                      ctFC['templateName'].value === '' ||
                        ctFC['templateName'].value === null;
                      else fcInvalid
                    "
                    >Template name can not be empty.</ng-container
                  >
                  <ng-template #fcInvalid>
                    <span *ngIf="ctFC['templateName'].errors"
                      >Please enter a valid template name.</span
                    >
                  </ng-template>
                </div>
              </div>
              <!-- END Template Name Error -->
            </div>
          </div>
        </div>
        <!-- ENd Template Name -->

        <!-- Report Type, Categories -->
        <div class="form-group">
          <div class="row">
            <!-- Report Type Selection -->
            <div class="col">
              <div class="input-label label">Type
                <span
                  class="text-danger"
                  matTooltip="Template Type is mandatory"
                  >*</span
                >
              </div>

              <ng-select
                [items]="templateTypeList"
                [placeholder]="'Select Type'"
                [multiple]="false"
                [searchable]="false"
                bindLabel="name"
                bindValue="value"
                formControlName="tempType"
                clearable="false"
                [closeOnSelect]="true"
                name="activerole"
                id="tempType"
              >
              </ng-select>
            </div>
            <!-- END Report Type Selection -->

            <!-- Categories Selection -->
            <div class="col">
              <div class="input-label label">Categories
                <span class="text-danger" matTooltip="Categories is mandatory">*</span>
              </div>

              <ng-select
                [items]="tempCategories"
                [placeholder]="'Select Category'"
                [multiple]="false"
                [searchable]="false"
                bindLabel="name"
                bindValue="value"
                groupBy="selectedAllGroup"
                formControlName="tempCategory"
                [closeOnSelect]="true"
                name="tempCategory"
                id="tempCategory"
              >
              </ng-select>
            </div>
            <!-- END Categories Selection -->
          </div>
        </div>
        <!-- END Report Type, Categories-->

        <!-- Template Parameters -->
        <div class="form-group">
          <div class="row">
            <div class="col">
              <div class="input-label label">Template Parameters
                <span
                  class="text-danger"
                  matTooltip="Template Parameters is mandatory"
                  >*</span
                >
              </div>

              <div
                class="parameters-wrapper d-flex align-items-center justify-content-between"
              >
                <div class="selected_chips_group">
                  <mat-chip-listbox aria-label="Enter campaigns" *ngIf="!showAllParameters">
                    <mat-chip-option
                      *ngFor="let item of selection.selected | slice : 0 : 10"
                      (removed)="
                        !clonetemplate ? selection.toggle(item) : null
                      "
                    >
                      {{ item.value }}
                      <button
                        matChipRemove
                        [attr.aria-label]="'remove ' + item"
                      >
                        <mat-icon *ngIf="!clonetemplate"
                          (click)="addParametersListToForm()"
                          (keydown)="addParametersListToForm()"
                        >cancel</mat-icon>
                      </button>
                    </mat-chip-option>
                  </mat-chip-listbox>
                  
                  <mat-chip-listbox aria-label="Enter campaigns" *ngIf="showAllParameters">
                    <mat-chip-option
                      *ngFor="let item of selection.selected"
                      (removed)="
                        !clonetemplate ? selection.toggle(item) : null
                      "
                    >
                      {{ item.value }}
                      <button
                        matChipRemove
                        [attr.aria-label]="'remove ' + item"
                      >
                        <mat-icon *ngIf="!clonetemplate"
                          (click)="addParametersListToForm()"
                          (keydown)="addParametersListToForm()"
                        >cancel</mat-icon>
                      </button>
                    </mat-chip-option>
                  </mat-chip-listbox>

                  <!-- More Template Parameters -->
                  <button
                    type="button"
                    class="btn btn-outline-secondary btn-xs more_btn"
                    *ngIf="selection.selected.length > 10 && !showAllParameters"
                    (click)="showAllParameters = !showAllParameters"
                  >
                    <em class="bi bi-plus-circle"></em>
                    {{ selection.selected.length - 10 }} More
                  </button>
                  <!-- END More Template Parameters -->
                  
                  <!-- View Less Template Parameters -->
                  <button
                    type="button"
                    class="btn btn-outline-secondary btn-xs less_btn"
                    *ngIf="showAllParameters && selection.selected.length > 0"
                    (click)="showAllParameters = !showAllParameters"
                  >
                    View Less
                  </button>
                  <!-- END View Less Template Parameters -->

                  <span>
                    <!-- Add Template Parameters Selection -->
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-xs"
                      [matMenuTriggerFor]="parametersMenu"
                      *ngIf="!clonetemplate"
                    >
                      <em class="bi bi-plus-circle"></em> Add
                    </button>
                    <!-- END Add Template Parameters Selection -->

                    <mat-menu #parametersMenu="matMenu">
                      <section
                        class="list_selection_panel"
                        (click)="$event.stopPropagation()"
                        (keydown)="$event.stopPropagation()"
                      >
                        <span class="select_all_option">
                          <mat-checkbox
                            class="list-checkboxes"
                            (change)="$event ? toggleAllRows() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="
                              selection.hasValue() && !isAllSelected()
                            "
                            [aria-label]="checkboxLabel()"
                          >
                            Select All
                          </mat-checkbox>
                        </span>
                        <span class="list-section">
                          <ul class="list-unstyled">
                            <li *ngFor="let item of templateparameterslist">
                              <mat-checkbox
                                class="list-checkboxes"
                                [(ngModel)]="item.isSelected"
                                (click)="$event.stopPropagation()"
                                (keydown)="$event.stopPropagation()"
                                (change)="
                                  $event ? selection.toggle(item) : null;
                                  addParametersListToForm()
                                "
                                [checked]="selection.isSelected(item)"
                                [aria-label]="checkboxLabel(item)"
                                [ngModelOptions]="{ standalone: true }"
                              >
                                {{ item.value }}
                              </mat-checkbox>
                            </li>
                          </ul>
                        </span>
                      </section>
                    </mat-menu>
                  </span>

                  <button
                    class="btn btn-primary btn-xs sava_as_btn"
                    type="submit"
                    [disabled]="createTemplateForm.invalid"
                  >
                    <em class="bi bi-save me-2"></em> Save Template
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END Template Parameters -->
      </form>
    </div>
  </div>
</div>
