/* eslint-disable no-useless-escape */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  NgbNavChangeEvent,
  NgbModal,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/alert/alert.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import * as commonactions from 'src/app/store/common.actions';
import { MediaFile } from 'src/app/appdata/fileserver.model';
import { SelectionModel } from '@angular/cdk/collections';
import {
  selectCommonDataDtls,
  selectProfilesDtlsData,
  selectSsoProfilesDtlsData,
} from 'src/app/store/common.selectors';
import {
  MediaDriveVariables,
  SharedService,
} from 'src/app/services/shared.service';
import {
  AuthUserData,
  RbacPolicies,
  SsoUserData,
} from 'src/app/appdata/auth.model';
import { skeleton_data } from '../whatsapp-preview/whatsapp-preview.data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-media-drive',
  templateUrl: './media-drive.component.html',
  styleUrls: ['./media-drive.component.scss'],
})
export class MediaDriveComponent implements OnInit, AfterViewInit, OnDestroy {
  mainTemplateData: any;
  active = 1;
  destroy$: Subject<boolean> = new Subject<boolean>();
  page = 1;
  pageSize = 10;
  deleteItem: any;
  userDetails: AuthUserData | SsoUserData;
  deleteMultipleItems: any;
  searchData: string = '';
  previewItem: any;
  file: File;
  mediafiles: MediaFile[] = [];
  skeletonData = skeleton_data;
  fileselection = new SelectionModel<MediaFile>(true, []);
  permissions: RbacPolicies;
  totalmediafiles: number = 0;
  uploadfiledata: any = null;
  isAnalysing: boolean = false;
  isUrlInserted: boolean = false;
  searchInput: boolean = false;
  isLoader: boolean;

  media_url: string;
  mediadrive_payload_var: MediaDriveVariables = {
    accountid: null,
    channel: null,
    bspid: null,
    wabano: null,
    page: this.page,
    size: this.pageSize,
    filetype: null,
    startdate: null,
    enddate: null,
    filename: null,
  };
  channelSub: Subscription;
  medialibraryDateSubscription: Subscription;

  @ViewChild('noMedia') private noMedia!: TemplateRef<any>;
  @ViewChild('mediaListing') private mediaListing!: TemplateRef<any>;
  @ViewChild('datawaiting') private datawaiting!: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private shareservice: SharedService,
    private alertMsg: AlertService,
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.store.dispatch(commonactions.clearuploadFile());
    if (environment.sso) {
      this.store
        .select(selectSsoProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
            this.permissions = res.loggedInUserDetails.rbac.rbac_policies;
          }
        });
    } else {
      this.store
        .select(selectProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
            this.permissions = this.userDetails.profile.acl.rbac.rbac_policies;
          }
        });
    }
    this.channelSub = this.shareservice.selectedBA$.subscribe((value: any) => {
      if (!value) {
        return;
      }

      const { account_id, bsp_id, waba_number, channel_name } =
        value.channel_credentials;

      this.mediadrive_payload_var = {
        ...this.mediadrive_payload_var,
        accountid: account_id,
        bspid: bsp_id,
        wabano: waba_number,
        channel: channel_name.toLowerCase(),
      };

      switch (this.active) {
        case 1:
          this.mediadrive_payload_var.filetype = null;
          break;
        case 2:
          this.mediadrive_payload_var.filetype = 'document';
          break;
        case 3:
          this.mediadrive_payload_var.filetype = 'image';
          break;
        case 4:
          this.mediadrive_payload_var.filetype = 'video';
          break;
        default:
          return;
      }

      this.store.dispatch(
        commonactions.fetchMediaServerData({
          payload: this.mediadrive_payload_var,
        })
      );
    });

    this.medialibraryDateSubscription =
      this.shareservice.datePickerObject.subscribe((value) => {
        if (value) {
          this.mediadrive_payload_var = JSON.parse(
            JSON.stringify(this.mediadrive_payload_var)
          );
          if (value.from && value.to) {
            const fromDate: NgbDateStruct = value.from;
            const toDate: NgbDateStruct = value.to;
            this.mediadrive_payload_var.startdate = `${fromDate.year}-${fromDate.month}-${fromDate.day}`;
            this.mediadrive_payload_var.enddate = `${toDate.year}-${toDate.month}-${toDate.day}`;
            this.store.dispatch(
              commonactions.fetchMediaServerData({
                payload: this.mediadrive_payload_var,
              })
            );
          }
          if (value.action === 'Date Cleared') {
            this.mediadrive_payload_var.startdate = null;
            this.mediadrive_payload_var.enddate = null;
            this.store.dispatch(
              commonactions.fetchMediaServerData({
                payload: this.mediadrive_payload_var,
              })
            );
          }
        }
      });

    this.store
      .select(selectCommonDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.customData) {
          this.isLoader = res.customData.isLoader;
          this.isAnalysing = res.customData.isAnalysing;
        }

        if (res.mediaServerFiles) {
          this.handleMediaServerFiles(res.mediaServerFiles);
        } else if (res.deletemediafiles_res) {
          this.handleDeleteMediaFiles(res.deletemediafiles_res);
        } else if (res.uploadRes) {
          this.handleUploadRes(res.uploadRes);
        } else if (res.error) {
          this.handleError(res.error);
        }
      });
  }

  private handleMediaServerFiles(data: any) {
    if (data.status_code === 200) {
      this.clearSelection();
      this.mediafiles = data.data.Files;
      this.totalmediafiles = data.data.total;
      this.mainTemplateData = {
        data: this.mediafiles?.length === 0 ? null : this.mediafiles,
        template:
          this.mediafiles?.length === 0 ? this.noMedia : this.mediaListing,
      };
    } else if (data.status_code === 404) {
      this.mediafiles = [];
      this.mainTemplateData = {
        data: this.mediafiles,
        template: this.mediaListing,
      };
    }
  }

  private handleDeleteMediaFiles(data: any) {
    if (data.status_code === 200) {
      const notDeletedIds = data.data.notDeletedIds;
      const undefinedIds = data.data.undefinedIds;
      const deletedIds = data.data.deletedIds;

      if (notDeletedIds.length > 0 || undefinedIds.length > 0) {
        this.handleDeleteErrors(notDeletedIds, undefinedIds);
      } else if (deletedIds.length > 0) {
        this.handleDeletedIds();
      }
    }
  }

  private handleUploadRes(data: any) {
    if (data.status_code === 200) {
      // this.isAnalysing = false;
      this.isUrlInserted = false;
      this.uploadfiledata = data.data;
      this.alertMsg.alertSuccess('Success!', data.message);
      this.store.dispatch(
        commonactions.fetchMediaServerData({
          payload: this.mediadrive_payload_var,
        })
      );
    }

    setTimeout(() => {
      this.modalService.dismissAll();
      this.uploadfiledata = null;
      this.file = null;
      this.isUrlInserted = false;
    }, 1500);
  }

  private handleError(error: any) {
    if (error.message) {
      this.mediafiles = [];
      this.mainTemplateData = {
        data: this.mediafiles,
        template: this.mediaListing,
      };
      const dispErr = error.message;
      this.alertMsg.alertDanger('Error', dispErr);
    }
    this.store.dispatch(commonactions.clearError());
    this.handleDeleteErrors([], []);
  }

  private handleDeleteErrors(notDeletedIds: string[], undefinedIds: string[]) {
    this.modalService.dismissAll();
    this.store.dispatch(
      commonactions.fetchMediaServerData({
        payload: this.mediadrive_payload_var,
      })
    );
    this.deleteItem = null;
    this.deleteMultipleItems = null;
    this.fileselection.clear();

    if (notDeletedIds.length > 0) {
      this.alertMsg.alertWarning(
        'Warning!',
        'Files with ids ' + notDeletedIds.toString() + ' are not deleted'
      );
    } else if (undefinedIds.length > 0) {
      this.alertMsg.alertWarning(
        'Warning!',
        'Files with ids ' + undefinedIds.toString() + ' not deleted'
      );
    }
  }

  private handleDeletedIds() {
    this.modalService.dismissAll();
    this.store.dispatch(
      commonactions.fetchMediaServerData({
        payload: this.mediadrive_payload_var,
      })
    );
    this.fileselection.clear();
    this.deleteItem = null;
    this.deleteMultipleItems = null;
    this.alertMsg.alertSuccess('Success', 'Selected File/s deleted');
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllFilesSelected() {
    const numSelected = this.fileselection.selected.length;
    const numRows = this.mediafiles.length;
    return numSelected === numRows;
  }

  toggleAllFileRows() {
    if (this.isAllFilesSelected()) {
      this.fileselection.clear();
      return;
    }

    this.fileselection.select(...this.mediafiles);
  }

  filecheckboxLabel(row?: MediaFile): string {
    if (!row) {
      return `${this.isAllFilesSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.fileselection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }

  selectHandler(row: MediaFile) {
    if (!this.fileselection.isSelected(row)) {
      this.fileselection.clear();
    }
    this.fileselection.toggle(row);
  }

  ngAfterViewInit(): void {
    this.mainTemplateData = {
      template: this.datawaiting,
    };
    this.cd.detectChanges();
  }

  uploadMedia() {
    return;
  }

  onSelect(event: any) {
    if (event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];
    const fileType = file.type;
    const fileSizeMB = file.size / 1024 / 1024;
    console.log(file, fileType, fileSizeMB);

    const fileLimits: Record<string, number> = {
      'text/csv': 2,
      'video/mp4': 16,
      'video/3gpp': 16,
      'audio/mp4': 16,
      'audio/ogg;codecs=opus': 16,
      'audio/mpeg': 16,
      'audio/amr': 16,
      'audio/aac': 16,
      'application/pdf': 100,
      'image/jpeg': 5,
      'image/jpg': 5,
      'image/png': 5,
      'image/webp': 0.1, // 100KB
      'image/gif': 0.1, // 100KB
    };

    const fileLimit = fileLimits[fileType];

    if (!fileLimit || fileSizeMB > fileLimit) {
      this.alertMsg.alertWarning('Warning!', this.getErrorMessage(fileType));
      return;
    }

    this.file = file;
    // this.isAnalysing = true;
    this.store.dispatch(
      commonactions.initiateuploadFile({
        file: this.file,
        accountid: this.mediadrive_payload_var.accountid,
        mobileno: this.mediadrive_payload_var.wabano,
        channel: this.mediadrive_payload_var.channel,
        createdby:
          this.userDetails.profile.first_name +
          ' ' +
          this.userDetails.profile.last_name,
        is_active: true,
      })
    );
  }

  getErrorMessage(fileType: string): string {
    switch (fileType) {
      case 'text/csv':
        return "You can't upload text/csv files which are greater than 2MB";
      case 'video/mp4':
      case 'video/3gpp':
        return "You can't upload video files which are greater than 16MB";
      case 'audio/mp4':
      case 'audio/ogg;codecs=opus':
      case 'audio/mpeg':
      case 'audio/amr':
      case 'audio/aac':
        return "You can't upload audio files which are greater than 16MB";
      case 'application/pdf':
        return "You can't upload documents which are greater than 100MB";
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/png':
        return "You can't upload images which are greater than 5MB";
      case 'image/webp':
      case 'image/gif':
        return "You can't upload stickers which are greater than 100KB";
      default:
        return 'Invalid file type';
    }
  }

  checkInsertedUrl(event: any) {
    const url = encodeURI(
      event.target.value || event.clipboardData.getData('text')
    );
    console.log(url);

    const pattern = new RegExp(
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi
    );

    const result = pattern.test(url);
    console.log(result);

    if (result) {
      this.isUrlInserted = true;
      this.media_url = url;
    } else {
      this.isUrlInserted = false;
      this.media_url = null;
    }
  }

  submitInsertedUrl() {
    this.store.dispatch(
      commonactions.initiateuploadUrl({
        url: this.media_url,
        accountid: this.mediadrive_payload_var.accountid,
        mobileno: this.mediadrive_payload_var.wabano,
        channel: this.mediadrive_payload_var.channel,
        createdby:
          this.userDetails.profile.first_name +
          ' ' +
          this.userDetails.profile.last_name,
      })
    );
  }

  closeUpload() {
    this.uploadfiledata = null;
    this.isUrlInserted = false;
    this.media_url = null;
  }

  onPageEvent(event: any) {
    this.page = event;
    this.mediadrive_payload_var = JSON.parse(
      JSON.stringify(this.mediadrive_payload_var)
    );
    this.mediadrive_payload_var.page = this.page;
    this.store.dispatch(
      commonactions.fetchMediaServerData({
        payload: this.mediadrive_payload_var,
      })
    );
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }

  applyFilter() {
    return;
  }

  openModal(content: TemplateRef<string>, data: string, item?: any) {
    // this.clearSelection();
    const sizes: any = {
      previewMedia: 'xl',
      fileupload: 'lg',
      addSample: 'xl',
      deleteTemplate: 'md',
    };

    if (data === 'deleteFileSingle') {
      this.deleteItem = item;
    } else if (data === 'deleteFileMultiple') {
      this.deleteMultipleItems = item;
    } else if (data === 'previewMedia') {
      this.previewItem = item;
    }
    this.modalService.open(content, {
      centered: true,
      scrollable: true,
      backdrop: 'static',
      size: sizes[data] || 'md',
    });
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.page = 1;
    this.pageSize = 10;
    this.searchData = '';
    this.clearSelection();

    this.mediadrive_payload_var = { ...this.mediadrive_payload_var };
    this.mediadrive_payload_var.filename = null;
    switch (changeEvent.nextId) {
      case 1:
        this.mediadrive_payload_var.filetype = null;
        break;
      case 2:
        this.mediadrive_payload_var.filetype = 'document';
        break;
      case 3:
        this.mediadrive_payload_var.filetype = 'image';
        break;
      case 4:
        this.mediadrive_payload_var.filetype = 'video';
        break;
      default:
        return;
    }

    // this.store.dispatch(
    //   commonactions.fetchMediaServerData({
    //     payload: { ...this.mediadrive_payload_var },
    //   })
    // );
  }

  downloadFile(item: any) {
    this.shareservice.downloadMediaFile(
      item.url,
      item.mime_type,
      item.file_name
    );
  }

  clearSelection() {
    this.fileselection.clear();
  }

  deleteMediaFiles() {
    const delete_payload: any = {
      ids: [],
    };
    if (this.deleteItem) {
      delete_payload.ids.push(this.deleteItem.id);
    } else if (this.deleteMultipleItems) {
      this.fileselection.selected.forEach((t) => {
        delete_payload.ids.push(t.id);
      });
    }
    this.store.dispatch(
      commonactions.deleteMediaServerData({ payload: delete_payload })
    );
  }

  cancelDelete() {
    this.modalService.dismissAll();
    this.deleteItem = null;
    this.deleteMultipleItems = null;
  }

  onSearchNameChange(event: any) {
    this.searchInput = this.shareservice.whiteSpaceValidator(event);
    console.log(this.searchInput, event, this.searchData);
    this.mediadrive_payload_var = JSON.parse(
      JSON.stringify(this.mediadrive_payload_var)
    );
    this.mediadrive_payload_var.page = 1;
    if (!event) {
      this.mediadrive_payload_var.filename = '';
      this.store.dispatch(
        commonactions.fetchMediaServerData({
          payload: this.mediadrive_payload_var,
        })
      );
    } else if (!this.searchInput && event) {
      this.mediadrive_payload_var.filename = this.searchData;
      this.store.dispatch(
        commonactions.fetchMediaServerData({
          payload: this.mediadrive_payload_var,
        })
      );
    }
  }

  ngOnDestroy() {
    this.channelSub.unsubscribe();
    this.medialibraryDateSubscription.unsubscribe();
    this.store.dispatch(commonactions.clearuploadFile());
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
