<!-- Main Content Container -->
<main role="main" class="main" id="consent_management">
  <!-- Display Content Template -->
  <div class="content container-fluid">
    <!-- Header -->
    <div class="card card-sticky">
      <div class="top_header_options">
        <h3 class="main_title">
          Consent
          <span
            class="badge badge-primary rounded-pill"
            matTooltip="Total Consent Count"
            >{{ consentservice.totalconsents | async }}</span
          >
        </h3>

        <div class="btn_options">
          <!-- Bulk Opt-In Button -->
          <button
            class="btn btn-primary"
            (click)="openModal(bulkOptInModal)"
            *ngIf="permissions.consent_management.can_create && active === 1"
          >
            Bulk Opt-In
          </button>
          <!-- END Bulk Opt-In Button -->

          <!-- Bulk Blocklist Button -->
          <button
            class="btn btn-primary"
            (click)="openModal(bulkOptInModal)"
            *ngIf="permissions.consent_management.can_create && active === 3"
          >
            Bulk Blocklist
          </button>
          <!-- END Bulk Blocklist Button -->
        </div>
      </div>
    </div>
    <!-- END Header -->

    <!-- Content -->
    <div class="card mt-2">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <!-- Opt-In -->
        <li [ngbNavItem]="1">
          <button ngbNavLink>Opt-In</button>
          <ng-template ngbNavContent>
            <app-optin></app-optin>
          </ng-template>
        </li>
        <!-- END Opt-In -->

        <!-- Opt-Out -->
        <li [ngbNavItem]="2">
          <button ngbNavLink>Opt-Out</button>
          <ng-template ngbNavContent>
            <app-optout></app-optout>
          </ng-template>
        </li>
        <!-- END Opt-Out -->

        <!-- BlockList -->
        <li [ngbNavItem]="3">
          <button ngbNavLink>Blocklist</button>
          <ng-template ngbNavContent>
            <app-blocklist></app-blocklist>
          </ng-template>
        </li>
        <!-- END BlockList -->
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
    <!-- END Content -->
  </div>
  <!-- END Display Content Template -->
</main>
<!-- END Main Content Container -->

<!-- Bulk Opt-in Modal template -->
<ng-template #bulkOptInModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Bulk {{active === 1 ? 'Opt-In' : active === 3 ? 'Blocklist':''}}
    </h4>
    <!-- <h4 class="modal-title" *ngIf="active === 3">Bulk Blocklist</h4> -->
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="file = null; d('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <!-- Analyzing Loader -->
    <div class="csv_analyze mt-3" *ngIf="isAnalysing">
    <span class="analyze_loader w-100">Uploading</span>
    </div>
    <!-- END Analyzing Loader -->

    <span *ngIf="!isAnalysing">
      <h5>Upload Mobile Number List</h5>
      <p class="modal_text">
        - First column should contain mobile number with country code.
      </p>
      <p class="modal_text">- Please upload only the CSV format (Upto 2 MB).</p>
      <p class="modal_text">
        - Click here(
        <span
          class="text-primary text-decoration-underline pointer"
          (click)="downloadSampleFile('text/csv;charset=utf-8')"
          (keydown)="downloadSampleFile('text/csv;charset=utf-8')"
          >CSV</span
        >
        ) to download a sample file.
      </p>
      <p class="modal_text mb-2">
        - Please make sure the file contains valid numbers. Invalid numbers will not be added or shown in the {{active === 1 ? "'Opt-In'" : active === 3 ? "'Blocklist'":''}} section.
      </p>
      <h6 class="my-2 upload_info_text">Choose File</h6>

      <!-- Csv/Excel Drag-n-Drop Area -->
      <fieldset
        class="media_upload_dropZone d-flex align-items-center justify-content-center gap-4"
        *ngIf="!file && !queue_status"
      >
        <legend class="visually-hidden">CSV file uploader</legend>
        <input
          class="upload_image"
          type="file"
          accept="text/csv"
          (change)="onSelect($event); $any($event.target).value = ''"
        />
        <div class="d-flex align-items-center gap-3">
          <div class="file_upload_icon">
            <em class="bi bi-upload fs-1 text-muted"></em>
          </div>
          <div class="files_supported text-center">
            <h4>
              Drag &amp; Drop file or
              <a class="text-primary">Browse</a>
            </h4>
            <h5 class="fw-light text-decoration-underline text-dark">
              <i class="bi-file-earmark-text-fill drag_and_drop_icon"></i>
              Choose Your CSV files here
            </h5>
          </div>
        </div>
      </fieldset>
      <!-- END Csv/Excel Drag-n-Drop Area -->

      <!-- CSV Uploading Loader -->
      <div class="file_uploading" *ngIf="queue_status">
        <h4>Uploading ...</h4>
        <p>
          <ngb-progressbar
            [showValue]="true"
            type="primary"
            [value]="queue_status.total_received_count"
            [max]="queue_status.total_sent_count"
          ></ngb-progressbar>
        </p>
      </div>
      <!-- END CSV Uploading Loader -->

      <!-- Uploaded Files -->
      <div class="upload_gallery my-2 gap-2" *ngIf="file && !queue_status">
        <div class="uploaded_image w-10">
          <img class="img-fluid" src="assets/img/excel_icon.svg" alt="CSV File" />
          <em class="bi bi-x-circle-fill img_close" 
            (click)="file = null" 
            (keydown)="file = null"
          > </em>
        </div>
        <div class="file_info">
          <h5>{{ file.name }}</h5>
          <p>Size: {{ file.size }}</p>
        </div>
      </div>
      <!-- END Uploaded Files -->
    </span>
  </div>

  <mat-divider class="my-2"></mat-divider>

  <div class="modal-footer">
    <button
      class="btn btn-outline-dark me-2"
      type="button"
      (click)="file = null; d('Cross click')"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      type="button"
      (click)="onBulkFileUpload()"
      [disabled]="!file"
    >
      Upload
    </button>
  </div>
</ng-template>
<!-- END Bulk Opt-in Modal template -->
