import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AuthUserData, RbacPolicies } from 'src/app/appdata/auth.model';
import { AlertService } from 'src/app/alert/alert.service';
import { SharedService } from 'src/app/services/shared.service';
import {
  selectProfilesDtlsData,
  selectSsoProfilesDtlsData,
} from 'src/app/store/common.selectors';
import {
  ReportTemplateVariables,
  TemplatesDatum,
} from 'src/app/services/reports.service';
import * as reportactions from 'src/app/customer/reports/store/reports.actions';
import { selectReportDetails } from '../store/reports.selectors';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { skeleton_data } from '../../whatsapp-preview/whatsapp-preview.data';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/services/CustomValidators';
import * as campaignactions from 'src/app/customer/campaign-manager/store/campaign.actions';
import { selectCampaignActionData } from '../../campaign-manager/store/campaign.selectors';
import { CampaignDetails } from 'src/app/appdata/campaign.model';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-templates',
  templateUrl: './report-templates.component.html',
  styleUrls: ['./report-templates.component.scss'],
})
export class ReportTemplatesComponent implements OnInit, OnDestroy {
  displayTemplate: any;
  selectedCategory: string;
  selectedTemp: any;
  channeldata: any;
  searchData: string = null;
  skeletonData = skeleton_data;

  exportReportTempForm: FormGroup;
  cloneReportTempForm: FormGroup;

  allCategories = [
    { name: 'Marketing', value: 'marketing' },
    { name: 'New Report', value: 'newreport' },
  ];
  displayedColumns: string[] = [
    'report_template_name',
    'report_type',
    'category',
    'report_parameters',
  ];

  page = 1;
  pageSize = 10;
  totalCampaigns: number = 0;
  exportTempCharCount = 0;
  cloneTempCharCount = 0;
  maxCharCount = 16;

  reportVariableData: ReportTemplateVariables = {
    account_id: null,
    channel: null,
    wabano: null,
    template_name: null,
    report_name: null,
    category: null,
    startdate: null,
    enddate: null,
    page: this.page,
    size: this.pageSize,
  };
  reportTemplatesList: TemplatesDatum[] = [];
  reportTemplatesCount: number = 0;
  campaignsList: CampaignDetails[] = [];

  isLoading: boolean = true;
  searching: boolean = false;
  isFirst: boolean = true;

  userDetails: AuthUserData | any;
  permissions: RbacPolicies;
  channelSub: Subscription;
  templateDataSubscription: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    private cv: CustomValidators,
    private readonly store: Store,
    private router: Router,
    private alertMsg: AlertService,
    private shareservice: SharedService,
    private modalService: NgbModal
  ) {}

  initiateForm() {
    this.exportReportTempForm = this.fb.group({
      reportName: [
        null,
        [Validators.required, Validators.pattern(this.cv.nameRegex)],
      ],
      dateRange: [null, Validators.required],
      campaignlist: [null, Validators.required],
    });

    this.cloneReportTempForm = this.fb.group({
      tempName: [
        null,
        [Validators.required, Validators.pattern(this.cv.nameRegex)],
      ],
      reportType: [null, Validators.required],
      tempCategory: [null, Validators.required],
    });

    this.exportReportTempForm.get('reportName').valueChanges.subscribe((t) => {
      this.exportTempCharCount = t ? t.length : 0;
    });

    this.cloneReportTempForm.get('tempName').valueChanges.subscribe((t) => {
      this.cloneTempCharCount = t ? t.length : 0;
    });
  }

  ngOnInit(): void {
    this.store.dispatch(reportactions.clearreportsdata());
    this.shareservice.unsubscribe();
    this.shareservice.customDateAction.next(7);

    this.initiateForm();
    this.subscribeToProfileData();
    this.subscribeToBA();
    this.subscribeToTemplatesData();
    // this.shareservice.datePickerObject.next(null);
    this.subscribeToDatePicker();
  }

  get erTempFC() {
    return this.exportReportTempForm.controls;
  }

  get crTempFC() {
    return this.cloneReportTempForm.controls;
  }

  subscribeToCampaignActions() {
    this.store
      .select(selectCampaignActionData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.campaignList && res.campaignList.status_code === 200) {
          this.campaignsList = res.campaignList.data;
          this.totalCampaigns = res.campaignList.count;
        }

        if (res.campaign_error) {
          const dispErr = res.campaign_error.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        } else if (res.error) {
          if (res.error && res.error.status_code === 404) {
            this.campaignsList = [];
            this.totalCampaigns = 0;
            const dispErr = res.error.message;
            this.alertMsg.alertDanger('Error', dispErr);
          }
        }
      });
  }

  subscribeToProfileData() {
    if (environment.sso) {
      this.store
        .select(selectSsoProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
            this.permissions = res.loggedInUserDetails.rbac.rbac_policies;
          }
        });
    } else {
      this.store
        .select(selectProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
            this.permissions = this.userDetails.profile.acl.rbac.rbac_policies;
          }
        });
    }
  }

  subscribeToBA() {
    this.channelSub = this.shareservice.selectedBA$.subscribe((value) => {
      if (value) {
        this.channeldata = value;
        this.reportVariableData = JSON.parse(
          JSON.stringify(this.reportVariableData)
        );
        this.reportVariableData.account_id =
          value.channel_credentials.account_id;
        this.reportVariableData.wabano = value.channel_credentials.waba_number;
        this.reportVariableData.channel = value.channel_credentials.channel;

        if (!this.isFirst) {
          this.store.dispatch(
            reportactions.reporttemplates.reportTemplatesList({
              payload: this.reportVariableData,
            })
          );
        }
        this.isFirst = false;

        // this.store.dispatch(
        //   reportactions.reporttemplates.reportTemplatesList({
        //     payload: this.reportVariableData,
        //   })
        // );
      }
    });
  }

  subscribeToTemplatesData() {
    this.store
      .select(selectReportDetails)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.reporttemplateslist) {
          this.isLoading = false;
          if (res.reporttemplateslist.status_code === 200) {
            this.reportTemplatesList =
              res.reporttemplateslist.data.templates_data;
            this.reportTemplatesCount = res.reporttemplateslist.data.count;
          } else {
            this.reportTemplatesList = [];
            this.reportTemplatesCount = 0;
          }
        } else if (res.deletereporttemplates) {
          if (res.deletereporttemplates.status_code === 200) {
            this.modalService.dismissAll();
            this.alertMsg.alertSuccess(
              'Success!',
              res.deletereporttemplates.message
            );
            this.store.dispatch(
              reportactions.reporttemplates.reportTemplatesList({
                payload: this.reportVariableData,
              })
            );
          }
        } else if (res.error) {
          this.handleError(res.error);
        }
      });
  }

  handleError(data: any) {
    this.modalService.dismissAll();
    this.isLoading = false;
    this.reportVariableData = JSON.parse(
      JSON.stringify(this.reportVariableData)
    );
    this.reportTemplatesList = [];
    this.reportTemplatesCount = 0;
    if (data?.message && !this.searching) {
      this.alertMsg.alertDanger('Error!', data.message);
    }
  }

  subscribeToDatePicker() {
    const datepicker = this.shareservice.datePickerObject.subscribe((value) => {
      if (value) {
        if (value.from && value.to) {
          const fromDate: NgbDateStruct = value.from;
          const toDate: NgbDateStruct = value.to;
          if (this.modalService.hasOpenModals()) {
            const camp_payload = {
              accountid: this.reportVariableData.account_id,
              channel: this.reportVariableData.channel,
              bspid: this.channeldata.channel_credentials.bsp_id,
              wabano: this.channeldata.channel_credentials.waba_number,
              startdate: `${fromDate.year}-${fromDate.month}-${fromDate.day}`,
              enddate: `${toDate.year}-${toDate.month}-${toDate.day}`,
            };
            this.exportReportTempForm
              .get('dateRange')
              .setValue(`${camp_payload.startdate} - ${camp_payload.enddate}`);
            this.store.dispatch(
              campaignactions.fetchcampaignslist({
                payload: camp_payload,
              })
            );
          } else {
            this.reportVariableData = JSON.parse(
              JSON.stringify(this.reportVariableData)
            );
            this.reportVariableData.page = null;
            this.reportVariableData.size = null;
            this.reportVariableData.startdate = `${fromDate.year}-${fromDate.month}-${fromDate.day}`;
            this.reportVariableData.enddate = `${toDate.year}-${toDate.month}-${toDate.day}`;

            this.isLoading = true;
            this.store.dispatch(
              reportactions.reporttemplates.reportTemplatesList({
                payload: this.reportVariableData,
              })
            );
          }
        }
        if (value.action === 'Date Cleared') {
          if (this.modalService.hasOpenModals()) {
            const camp_payload: any = {
              accountid: this.reportVariableData.account_id,
              channel: this.reportVariableData.channel,
              bspid: this.channeldata.channel_credentials.bsp_id,
              wabano: this.channeldata.channel_credentals.waba_number,
              startdate: null,
              enddate: null,
            };
            this.exportReportTempForm.get('dateRange').setValue(null);
            this.store.dispatch(
              campaignactions.fetchcampaignslist({
                payload: camp_payload,
              })
            );
          } else {
            this.reportVariableData = JSON.parse(
              JSON.stringify(this.reportVariableData)
            );
            this.reportVariableData.startdate = null;
            this.reportVariableData.enddate = null;
            this.reportVariableData.page = 1;
            this.reportVariableData.size = this.pageSize;
            this.store.dispatch(
              reportactions.reporttemplates.reportTemplatesList({
                payload: this.reportVariableData,
              })
            );
          }
        }
      }
    });
    this.shareservice.subscribe(datepicker);
  }

  searchName(data: string) {
    if (data && data.length > 2 && data.trim()) {
      this.searching = true;
      this.reportVariableData = JSON.parse(
        JSON.stringify(this.reportVariableData)
      );
      this.reportVariableData.template_name = data.trim();
      this.store.dispatch(
        reportactions.reporttemplates.reportTemplatesList({
          payload: this.reportVariableData,
        })
      );
    } else {
      this.searching = false;
    }
  }

  onSearchNameChange(event: any) {
    if (!event) {
      this.searching = false;
      this.reportVariableData = JSON.parse(
        JSON.stringify(this.reportVariableData)
      );
      this.reportVariableData.template_name = null;
      this.store.dispatch(
        reportactions.reporttemplates.reportTemplatesList({
          payload: this.reportVariableData,
        })
      );
    } else {
      this.searchName(event);
    }
  }

  onCategoryChange(event: any) {
    this.reportVariableData = JSON.parse(
      JSON.stringify(this.reportVariableData)
    );
    if (event) {
      this.reportVariableData.category = event;
      this.store.dispatch(
        reportactions.reporttemplates.reportTemplatesList({
          payload: this.reportVariableData,
        })
      );
    } else {
      this.reportVariableData.category = null;
      this.store.dispatch(
        reportactions.reporttemplates.reportTemplatesList({
          payload: this.reportVariableData,
        })
      );
    }
  }

  initiateExportTemplate(data: string) {
    this.router.navigate([
      'customer',
      this.userDetails.profile.id,
      'reports',
      'export',
      data,
    ]);
  }

  onPageEvent(event: any) {
    this.page = event;
    this.reportVariableData = JSON.parse(
      JSON.stringify(this.reportVariableData)
    );
    this.reportVariableData.page = this.page;
    this.reportVariableData.size = this.pageSize;
    this.store.dispatch(
      reportactions.reporttemplates.reportTemplatesList({
        payload: this.reportVariableData,
      })
    );
  }

  getReportProperties(data: any) {
    if (typeof data === 'string') {
      return JSON.parse(data);
    } else {
      return 'N/A';
    }
  }

  openModal(content: TemplateRef<string>, data: string, selItem?: any) {
    const sizes: any = {
      viewTemplate: 'lg',
      addSample: 'xl',
      deleteTemplate: 'md',
    };

    if (selItem) {
      this.selectedTemp = selItem;
    }

    this.modalService.open(content, {
      centered: true,
      scrollable: true,
      backdrop: 'static',
      size: sizes[data] || 'md',
    });
  }

  deleteTemplate() {
    const del_payload: any = {
      ids: [this.selectedTemp.id],
    };
    this.store.dispatch(
      reportactions.reporttemplates.deleteReportTemplate({
        payload: del_payload,
      })
    );
  }

  openCloneAction(data: string) {
    this.router.navigate([
      'customer',
      this.userDetails.profile.id,
      'reports',
      'clone',
      data,
    ]);
  }

  ngOnDestroy() {
    this.channelSub.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    // this.templateDataSubscription.unsubscribe();
    // this.shareservice.datePickerObject.next(null);
    this.store.dispatch(reportactions.clearreportsdata());
  }
}
