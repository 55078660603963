import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription, takeUntil } from 'rxjs';
import {
  AuthUserData,
  ChannelCredentials,
  RbacPolicies,
} from 'src/app/appdata/auth.model';
import {
  ReportListVariables,
  // ReportTemplateVariables,
  ReportsDatum,
} from 'src/app/services/reports.service';
import { SharedService } from 'src/app/services/shared.service';
import {
  selectProfilesDtlsData,
  selectSsoProfilesDtlsData,
} from 'src/app/store/common.selectors';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as reportactions from 'src/app/customer/reports/store/reports.actions';
import { selectReportDetails } from '../store/reports.selectors';
import { AlertService } from 'src/app/alert/alert.service';
import { skeleton_data } from '../../whatsapp-preview/whatsapp-preview.data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss'],
})
export class ReportsListComponent implements OnInit, OnDestroy {
  displayTemplate: any;
  searchData: string = null;
  skeletonData = skeleton_data;
  channel_credentails: ChannelCredentials;
  page = 1;
  pageSize = 10;
  reportVariableData: ReportListVariables = {
    account_id: null,
    channel: null,
    wabano: null,
    template_name: null,
    report_name: null,
    category: null,
    startdate: null,
    enddate: null,
    page: this.page,
    size: this.pageSize,
  };
  isLoading: boolean = true;
  searching: boolean = false;
  isFirst: boolean = true;

  channelSub: Subscription;
  reportDataSubscription: Subscription;
  userDetails: AuthUserData | any;
  permissions: RbacPolicies;
  selectedCategory: string;
  allCategories = [{ name: 'Marketing', value: 'marketing' }];

  displayedColumns: string[] = [
    'report_name',
    'creation_date',
    'date_range',
    'report_type',
    'category',
    'report_properties',
    'campaign_ids',
    'created_by',
    'status',
  ];

  reportsList: ReportsDatum[] = [];
  totalreportscount: number = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly store: Store,
    private alertMsg: AlertService,
    private shareservice: SharedService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(reportactions.clearreportsdata());
    this.shareservice.unsubscribe();
    this.shareservice.customDateAction.next(7);

    this.subscribeToProfileData();
    this.subscribeToBA();
    this.subscribeToReportsData();
    // this.shareservice.datePickerObject.next(null);
    this.subscribeToDatePicker();
  }

  subscribeToProfileData() {
    if (environment.sso) {
      this.store
        .select(selectSsoProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
            this.permissions = res.loggedInUserDetails.rbac.rbac_policies;
          }
        });
    } else {
      this.store
        .select(selectProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
            this.permissions = this.userDetails.profile.acl.rbac.rbac_policies;
          }
        });
    }
  }

  subscribeToBA() {
    this.channelSub = this.shareservice.selectedBA$.subscribe((value) => {
      if (value) {
        this.channel_credentails = value.channel_credentials;
        this.reportVariableData = JSON.parse(
          JSON.stringify(this.reportVariableData)
        );
        this.reportVariableData.account_id =
          value.channel_credentials.account_id;
        this.reportVariableData.wabano = value.channel_credentials.waba_number;
        this.reportVariableData.channel = value.channel_credentials.channel;
        this.isLoading = true;

        if (!this.isFirst) {
          this.store.dispatch(
            reportactions.reportdetails.reportList({
              payload: this.reportVariableData,
            })
          );
        }
        this.isFirst = false;

        // this.store.dispatch(
        //   reportactions.reportdetails.reportList({
        //     payload: this.reportVariableData,
        //   })
        // );
      }
    });
  }

  subscribeToReportsData() {
    this.store
      .select(selectReportDetails)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.reportlist) {
          this.isLoading = false;
          if (res.reportlist.status_code === 200) {
            this.reportsList = res.reportlist.data.reports_data;
            this.totalreportscount = res.reportlist.data.count;
          } else {
            this.reportsList = [];
            this.totalreportscount = 0;
          }
        } else if (res.error) {
          this.isLoading = false;
          this.reportsList = [];
          this.totalreportscount = 0;
          if (res.error?.message && !this.searching) {
            this.alertMsg.alertDanger('Error!', res.error.message);
          }
        }
      });
  }

  subscribeToDatePicker() {
    // reportDataSubscription
    const datepicker = this.shareservice.datePickerObject.subscribe((value) => {
      if (value) {
        if (value.from && value.to) {
          const fromDate: NgbDateStruct = value.from;
          const toDate: NgbDateStruct = value.to;

          this.reportVariableData = JSON.parse(
            JSON.stringify(this.reportVariableData)
          );
          this.reportVariableData.page = this.page;
          this.reportVariableData.size = this.pageSize;
          this.reportVariableData.startdate = `${fromDate.year}-${fromDate.month}-${fromDate.day}`;
          this.reportVariableData.enddate = `${toDate.year}-${toDate.month}-${toDate.day}`;
          this.isLoading = true;
          this.store.dispatch(
            reportactions.reportdetails.reportList({
              payload: this.reportVariableData,
            })
          );
        }
        if (value.action === 'Date Cleared') {
          this.reportVariableData = JSON.parse(
            JSON.stringify(this.reportVariableData)
          );
          this.reportVariableData.startdate = null;
          this.reportVariableData.enddate = null;
          this.reportVariableData.page = 1;
          this.reportVariableData.size = this.pageSize;
          this.store.dispatch(
            reportactions.reportdetails.reportList({
              payload: this.reportVariableData,
            })
          );
        }
      }
    });
    this.shareservice.subscribe(datepicker);
  }

  searchName(data: string) {
    if (data.trim() && data && data.length > 2) {
      this.searching = true;
      this.reportVariableData = JSON.parse(
        JSON.stringify(this.reportVariableData)
      );
      this.reportVariableData.report_name = data.trim();
      this.store.dispatch(
        reportactions.reportdetails.reportList({
          payload: this.reportVariableData,
        })
      );
    } else {
      this.searching = false;
    }
  }

  onSearchNameChange(event: any) {
    if (!event) {
      this.searching = false;
      this.reportVariableData = JSON.parse(
        JSON.stringify(this.reportVariableData)
      );
      this.reportVariableData.report_name = null;
      this.store.dispatch(
        reportactions.reportdetails.reportList({
          payload: this.reportVariableData,
        })
      );
    } else {
      this.searchName(event);
    }
  }

  onCategoryChange(event: any) {
    this.reportVariableData = JSON.parse(
      JSON.stringify(this.reportVariableData)
    );
    this.isLoading = true;
    if (event) {
      this.reportVariableData.category = event;
      this.store.dispatch(
        reportactions.reportdetails.reportList({
          payload: this.reportVariableData,
        })
      );
    } else {
      this.reportVariableData.category = null;
      this.store.dispatch(
        reportactions.reportdetails.reportList({
          payload: this.reportVariableData,
        })
      );
    }
  }

  getReportList() {
    this.isLoading = true;
    this.store.dispatch(
      reportactions.reportdetails.reportList({
        payload: this.reportVariableData,
      })
    );
  }

  getReportProperties(data: any) {
    if (typeof data === 'string') {
      return JSON.parse(data);
    } else {
      return 'N/A';
    }
  }

  getCampaignIds(data: any) {
    if (typeof data === 'string') {
      return JSON.parse(data);
    } else {
      return [];
    }
  }

  downloadReport(data: ReportsDatum) {
    const file_name = data.report_name;
    const url = data.location_uri;
    this.shareservice.downloadSampleFile(url, 'zip', file_name + '.csv.zip');
  }

  onPageEvent(event: any) {
    this.page = event;
    this.reportVariableData = JSON.parse(
      JSON.stringify(this.reportVariableData)
    );
    this.reportVariableData.page = this.page;
    this.reportVariableData.size = this.pageSize;
    this.store.dispatch(
      reportactions.reportdetails.reportList({
        payload: this.reportVariableData,
      })
    );
  }

  ngOnDestroy() {
    this.channelSub.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    // this.reportDataSubscription.unsubscribe();
    // this.shareservice.datePickerObject.next(null);
  }
}
